import { useContext, useRef, useState } from "react";
import {
  FaChartArea,
  FaChartLine,
  FaStar,
  FaUserFriends,
  FaUserTie,
} from "react-icons/fa";

// api
import staffApi from "../../api/Staff";
import subjectApi from "../../api/Subject";
import classApi from "../../api/Classes";

//css
import "./Dashboard.css";

//components
import Card from "../../components/card/Card";
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import Barchart from "../../components/chart/barchart/Barchart";
import StatCard from "../../components/statcard/StatCard";
import AppCalendar from "../../components/calendar/AppCalendar";
import AppWrapper from "../../components/appWrapper/AppWrapper";

//data
import {
  empty,
  isArray,
  prepareResponseData,
  reIndex,
} from "../../Utilities/utils";
import { AuthContext } from "../Root/ProtectedRoute";
import { Toast } from "primereact/toast";
import { useEffect } from "react";
import { colors } from "../../Utilities/colors";
import { NavLink } from "react-router-dom";

const Dashboard = ({ ...props }) => {
  const { user } = useContext(AuthContext);
  const [value, onChange] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [isNoOfStudentLoading, setIsNoOfStudentLoading] = useState(false);
  const [noOfStaffAssignedSubjects, setNoOfStaffAssignedSubjects] = useState(0);
  const [noOfClasses, setNoOfClasses] = useState(0);
  const [studentPerformance, setStudentPerformance] = useState(0);
  const [reIndexedSubjects, setReIndexedSubjects] = useState({});
  const [staffClasses, setStaffClasses] = useState([]);
  const [reIndexedClasses, setReIndexedClasses] = useState({});

  const authorizedSubjects =
    !empty(user) &&
    !empty(user.authorizedSubjects) &&
    isArray(user.authorizedSubjects)
      ? user.authorizedSubjects
      : [];

  const assessmentData = [];
  const [chartData, setChartData] = useState({
    labels: assessmentData.map((data) => {
      const classTitle = !empty(data) && !empty(data.title) ? data.title : "";
      return classTitle;
    }),
    datasets: [
      {
        label: "Class Performance",
        data: assessmentData.map((data) => {
          const sum = !empty(data) && !empty(data.sum) ? data.sum : 0;
          return sum;
        }),
        backgroundColor: "#6d4dc7d6",
        borderRadius: 12,
      },
    ],
  });
  const toastTR = useRef(null);

  useEffect(() => {
    const assignedClasses =
      !empty(user) && !empty(user.classes) && isArray(user.classes)
        ? user.classes
        : [];
    setNoOfClasses(assignedClasses.length);
    getSchoolAssessmentsData();
    getClasses();
    getSubjects();
  }, [user]);

  // alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR?.current?.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  const getSchoolAssessmentsData = async () => {
    setIsNoOfStudentLoading(true);
    try {
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await staffApi.getSchoolAssessmentsData(schoolId);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.response) ||
        !response_data?.response
      ) {
        return;
      }
      const _assessmentData =
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : [];
      setChartData({
        labels: _assessmentData.map((data) => {
          const classTitle =
            !empty(data) && !empty(data.title) ? data.title : "";
          return classTitle;
        }),
        datasets: [
          {
            label: "Class Performance",
            data: _assessmentData.map((data) => {
              const sum = !empty(data) && !empty(data.sum) ? data.sum : 0;
              return sum;
            }),
            backgroundColor: _assessmentData.map((data) => {
              const sum = !empty(data) && !empty(data.sum) ? data.sum : 0;
              let colorCode = "#6d4dc7d6";
              if (sum < 50) {
                colorCode = colors.danger;
              }
              return colorCode;
            }),
            borderRadius: 12,
          },
        ],
      });
    } catch (error) {
      responseDailog("error", "Assessment Data", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const getSubjects = async () => {
    try {
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await subjectApi.getSubjects(schoolId);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return setReIndexedSubjects({});
      }
      const results =
        !empty(response_data) &&
        !empty(response_data.response) &&
        isArray(response_data.response)
          ? response_data.response
          : [];
      const _reIndexedSubjects = !empty(results) ? reIndex(results, "_id") : {};
      setReIndexedSubjects(_reIndexedSubjects);

      let assignedSubjects = [];
      const subjects = authorizedSubjects.map((data) => {
        const subjectIds =
          !empty(data) && !empty(data.subjectIds) && isArray(data.subjectIds)
            ? data.subjectIds
            : [];
        assignedSubjects = [...new Set([...assignedSubjects, ...subjectIds])];
      });
      setNoOfStaffAssignedSubjects(
        isArray(assignedSubjects) ? assignedSubjects.length : 0
      );
    } catch (error) {
      responseDailog("error", "Error Alert", "Failed to fetch subjects.");
    }
  };

  const getClasses = async () => {
    try {
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await classApi.getClasses(schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "Something went wrong!"
            : "Something went wrong"
        );
      }
      const data =
        !empty(response_data) &&
        !empty(response_data.response) &&
        isArray(response_data.response)
          ? response_data.response
          : [];
      const _staffClasses = [];
      const _reIndexedClasses = !empty(data) ? reIndex(data, "_id") : {};
      const assignedClasses =
        !empty(user) && !empty(user.classes) && isArray(user.classes)
          ? user.classes
          : [];
      assignedClasses.map((data) => {
        if (!empty(_reIndexedClasses) && !empty(_reIndexedClasses[data])) {
          _staffClasses.push(_reIndexedClasses[data]);
        }
      }, []);
      setStaffClasses(_staffClasses);
      setReIndexedClasses(_reIndexedClasses);
    } catch (error) {
      responseDailog("error", "Error Alert", "Failed to fetch subjects.");
    }
  };

  return (
    <>
      <AppWrapper {...props}>
        <main>
          {/* menu header */}
          <MainHeader title="Hello, Welcome Back!" leftIcon="&#128075; " />
          {/* end menu header */}
          <div className="mt-10 dashboard_container">
            {/* left box */}
            <div id="main_left_box">
              {/* stat box */}
              <div className="top_stat_box">
                <Card
                  children={
                    <StatCard
                      underlineWidth={15}
                      bgColor={colors.primary}
                      title="Assigned Subjects"
                      entry={noOfStaffAssignedSubjects}
                      icon={<FaUserTie className="stat_card_icon" />}
                    />
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    <StatCard
                      underlineWidth={15}
                      bgColor={colors.primary}
                      title="Assigned Classes"
                      entry={noOfClasses}
                      icon={<FaUserFriends className="stat_card_icon" />}
                    />
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    <StatCard
                      underlineWidth={15}
                      bgColor={colors.primary}
                      title="My Assessment"
                      entry={studentPerformance + "%"}
                      icon={<FaChartLine className="stat_card_icon" />}
                    />
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    <StatCard
                      underlineWidth={15}
                      bgColor={colors.primary}
                      title="My Attendance"
                      entry="N/A"
                      icon={<FaChartArea className="stat_card_icon" />}
                    />
                  }
                  addStyle="card_adjust_stat"
                />
              </div>
              {/* stat header end */}

              {/* chart */}
              <div className="chart_box">
                <Card
                  children={
                    <>
                      <MainHeader title="Class Performance" />
                      <Barchart data={chartData} />
                    </>
                  }
                  addStyle="barchart"
                />
              </div>
              {/* end chart */}

              {/* assigned subjects and classes */}
              <div className="flex subjects-class-container">
                <Card
                  children={
                    <>
                      <MainHeader title="Assigned Subjects" />
                      <div className="mt-10">
                        {authorizedSubjects.map((item, index) => {
                          const _classId =
                            !empty(item) && !empty(item.classId)
                              ? item.classId
                              : "";
                          const _subjectIds =
                            !empty(item) && !empty(item.subjectIds)
                              ? item.subjectIds
                              : [];
                          const _classTitle =
                            reIndexedClasses?.[_classId]?.title || "";

                          const subjects = _subjectIds.map((item) => {
                            const title =
                              !empty(reIndexedSubjects) &&
                              !empty(reIndexedSubjects[item]) &&
                              !empty(reIndexedSubjects[item].title)
                                ? reIndexedSubjects[item].title
                                : "";
                            return title;
                          });

                          return (
                            <div
                              key={index}
                              className="mb-10 flex direction-column bio-data-item"
                            >
                              <span
                                style={{
                                  color: "#633ccd",
                                }}
                              >
                                <FaStar
                                  style={{
                                    marginRight: 8,
                                    fontSize: 12,
                                  }}
                                  color="#DAA520"
                                />
                                {_classTitle}:
                              </span>
                              <div
                                key={index}
                                style={{
                                  cursor: "pointer",
                                  marginTop: 5,
                                  fontSize: 14,
                                }}
                              >
                                <ul className="ml-15">
                                  {subjects.map((data, idex) => (
                                    <li className="mb-5" key={idex}>
                                      {data}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  }
                  addStyle="dashboard-aassigned-items sm-100"
                />
                <Card
                  children={
                    <>
                      <MainHeader title="Assigned Class(es)" />
                      <div className="mt-10">
                        {staffClasses.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="mb-10 flex space-between bio-data-item"
                            >
                              <span>
                                <FaStar
                                  style={{
                                    marginRight: 8,
                                    fontSize: 12,
                                  }}
                                  color="#DAA520"
                                />
                                {!empty(item) && !empty(item.title) ? (
                                  <NavLink
                                    style={{
                                      textDecoration: "none",
                                      color: "#633ccd",
                                    }}
                                    to={`/classes/${
                                      !empty(item) && !empty(item._id)
                                        ? item._id + "/profile"
                                        : ""
                                    }`}
                                  >
                                    {item.title}
                                  </NavLink>
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  }
                  addStyle="dashboard-aassigned-items sm-100"
                />
              </div>
              {/* end of assigned subjects and classes */}
            </div>
            {/* end of left box */}

            {/* right box */}
            <div id="main_right_box">
              {/* calendar */}
              <Card
                children={
                  <AppCalendar
                    onChange={onChange}
                    value={value}
                    onClickDay={(value) => alert(value)}
                  />
                }
                addStyle="card_adjust"
              />

              {/* end of calendar */}
              <div className="main_right_boxes">
                {/* activities */}
                <Card
                  children={
                    <>
                      <MainHeader
                        title="Activities"
                        buttonHeight={25}
                        textSize={12}
                      />
                      {/* {ActivityData.map((activity) => {
                        return (
                          <ListTitleSubtitle
                            key={activity.id}
                            date={activity.date}
                            time={activity.time}
                            title={activity.title}
                            subtitle={activity.subtitle}
                            borderColor={activity.color}
                          />
                        );
                      })} */}
                    </>
                  }
                  addStyle="card_adjust"
                />
                {/* end of activities */}
              </div>
            </div>
          </div>
          {/* end of right box */}
        </main>
        <Toast ref={toastTR} position="bottom-left" />
      </AppWrapper>
    </>
  );
};

export default Dashboard;
