import { useContext, useState } from "react";
import {
  FaBars,
  FaRegBell,
  FaCaretDown,
  FaRegEnvelope,
  FaTimes,
  FaUser,
  FaEnvelope,
  FaBell,
  FaCogs,
  FaSignOutAlt,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../screens/Root/ProtectedRoute";
import { empty } from "../../Utilities/utils";

// css
import "./Navbar.css";

// images
import appLogo from "../../assets/logo/blue-logo.png";
import colors from "../../config/colors";
import Card from "../card/Card";

const Navbar = ({ sidebarOpen, toggleSidebar }) => {
  const { user } = useContext(AuthContext);
  const [showAvatarMenu, setShowAvatarMenu] = useState(false);

  const openProfileMenu = () => {
    setShowAvatarMenu(!showAvatarMenu);
  };
  const firstName =
    !empty(user) && !empty(user.firstName) ? user.firstName : "NA";
  const schoolName =
    !empty(user) &&
    !empty(user.schoolData) &&
    !empty(user.schoolData.schoolName)
      ? user.schoolData.schoolName
      : "";
  const schoolAvatar =
    !empty(user) &&
    !empty(user.schoolData) &&
    !empty(user.schoolData.schoolAvatar)
      ? user.schoolData.schoolAvatar
      : "";
  const userAvatar = !empty(user) && !empty(user.avatar) ? user.avatar : "";

  return (
    <nav className="navbar">
      <div className="nav_icon" onClick={toggleSidebar}>
        {!sidebarOpen ? (
          <FaBars className="menu_bar" />
        ) : (
          <FaTimes className="menu_bar" />
        )}
      </div>
      <div className="navbar_left">
        <span style={{ fontWeight: "bold", fontSize: 20 }}>{schoolName}</span>
      </div>
      <div className="navbar_right">
        <NavLink to="/inbox" style={{ textDecoration: "none" }} className="">
          <div className="icon_box">
            <FaRegEnvelope />
          </div>
        </NavLink>
        <NavLink
          to="/notification"
          style={{ textDecoration: "none" }}
          className=""
        >
          <div className="icon_box">
            <FaRegBell />
            <div></div>
          </div>
        </NavLink>
        <div className="navbar_avatar_container">
          <div className="navbar_avatar_box " onClick={() => openProfileMenu()}>
            <img
              className="navbar_avatar_box_img"
              src={!empty(userAvatar) ? userAvatar : schoolAvatar}
              alt="avatar"
            />
            <span className="navbar_user_name">{firstName}</span>
            <span>
              <FaCaretDown />
            </span>
          </div>
          <Card
            addStyle={`avatar_dropdown_menu ${
              showAvatarMenu ? "flex" : "hide"
            }`}
          >
            <ul className="avatar_main_menu">
              <li>
                <NavLink
                  to="/profile"
                  style={{ textDecoration: "none", color: colors.primary }}
                >
                  <FaUser
                    style={{ marginRight: 13, color: colors.gray }}
                    size={14}
                  />{" "}
                  Profile
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/settings"
                  style={{ textDecoration: "none", color: colors.primary }}
                >
                  <FaCogs
                    style={{ marginRight: 13, color: colors.gray }}
                    size={14}
                  />{" "}
                  Settings
                </NavLink>
              </li>
              <hr />
              <li
                style={{
                  marginTop: 10,
                }}
              >
                <NavLink
                  to="/logout"
                  style={{
                    textDecoration: "none",
                    color: colors.danger,
                  }}
                >
                  <FaSignOutAlt
                    style={{ marginRight: 13, color: colors.gray }}
                    size={14}
                  />{" "}
                  Log Out
                </NavLink>
              </li>
            </ul>
          </Card>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
