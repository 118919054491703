import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { AuthContext } from "../Root/ProtectedRoute";
import { isObject } from "lodash";

// css
import "../students/Students.css";
import "./Assessment.css";

// api
import studentApi from "../../api/Student";
import classApi from "../../api/Classes";

//components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import { Toast } from "primereact/toast";
import StudentsTable from "../../components/tables/primeTable/students/StudentsTable";
import FullPageLoader from "../../components/loader/FullPageLoader";
import TableLoading from "../../components/skeleton/TableLoading";

const ReportSheet = ({ ...props }) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const paramState = !empty(location.state) ? location.state : {};
  const classId =
    !empty(paramState) && !empty(paramState.classId) ? paramState.classId : "";
  const sessionId =
    !empty(paramState) && !empty(paramState.sessionId)
      ? paramState.sessionId
      : "";
  const termId =
    !empty(paramState) && !empty(paramState.termId) ? paramState.termId : "";
  //ref
  const toastTR = useRef(null);
  // states
  const [studentData, setStudentData] = useState([]);
  const [classData, setClassData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(50);
  const [search, setSearch] = useState("");
  const [subjectTitle, setSubjectTitle] = useState("");

  useEffect(() => {
    // fetch student
    try {
      const loadData = async () => {
        await getStudents();
        await getClassDetails();
      };
      if (!empty(classId) && !empty(sessionId) && !empty(termId)) {
        loadData();
      } else {
        navigate("/404");
      }
    } catch (error) {
      responseDailog(
        "error",
        "Something went wrong",
        "Failed to load student. Please try again later."
      );
    }
  }, [first, rows, search, classId, user]);

  //alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR?.current?.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  // function to get all student
  const getStudents = async (returnValue = false) => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const page = isNaN(first / rows) ? 0 : first / rows;
      let response;
      response = await studentApi.getStudentsInClass(
        schoolId,
        page,
        rows,
        search,
        classId,
        "assessment"
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setStudentData([]);
      } else {
        const returnData = !empty(response_data.response)
          ? response_data.response
          : [];
        if (returnValue) {
          return returnData;
        } else {
          setStudentData(returnData);
          setTotalRecords(
            !empty(response_data.count) ? response_data.count : 0
          );
        }
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong.`);
    } finally {
      setIsLoading(false);
    }
  };

  const getClassDetails = async () => {
    try {
      if (!isActionLoading) setIsActionLoading(true);
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await classApi.getSingleClass(classId, schoolId);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return setClassData({});
      } else {
        const data =
          !empty(response_data) &&
          !empty(response_data.response) &&
          isObject(response_data.response)
            ? response_data.response
            : {};
        setClassData(data);
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong.`);
    } finally {
      setIsActionLoading(false);
    }
  };

  // function to show result sheet
  const getStudentReportSheet = (data) => {
    const studentId = !empty(data) && !empty(data._id) ? data._id : "";

    if (
      !empty(studentId) &&
      !empty(sessionId) &&
      !empty(termId) &&
      !empty(classId)
    ) {
      return navigate(
        `/student/${studentId}/report-sheet/session/${sessionId}/term/${termId}/class/${classId}`
      );
    }

    responseDailog(
      "error",
      "Error Alert",
      `Something went wrong. Failed to redirect to report sheet view.`
    );
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const onSearchChange = (event) => {
    setSearch(event.target.value);
    setFirst(0);
  };

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="tableCard">
            {/*  header start */}
            <MainHeader
              title="Students"
              children={
                <>
                  <span style={{ marginRight: 30 }}>
                    Class:{" "}
                    <strong>
                      {!empty(classData) && !empty(classData.title)
                        ? classData.title
                        : ""}
                    </strong>
                  </span>
                </>
              }
            />
            {/* end of header */}

            {/* table start */}
            {!isLoading ? (
              <StudentsTable
                students={studentData}
                getStudentReportScreen={getStudentReportSheet}
                onPageChange={onPageChange}
                loading={isLoading}
                rows={rows}
                totalRecords={totalRecords}
                first={first}
                search={search}
                onSearchChange={onSearchChange}
                assessment={true}
                reportSheet={true}
              />
            ) : (
              <TableLoading rows={20} cols={7} header={false} />
            )}
            {/* table end  */}
          </div>
        </main>
        {isActionLoading && <FullPageLoader visible={isActionLoading} />}
      </AppWrapper>
      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default ReportSheet;
