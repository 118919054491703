import { useContext, useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { AuthContext } from "../Root/ProtectedRoute";

// css
import "./Students.css";

// api
import studentApi from "../../api/Student";

//components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import { Toast } from "primereact/toast";
import StudentsTable from "../../components/tables/primeTable/students/StudentsTable";
import TableLoading from "../../components/skeleton/TableLoading";
import colors from "../../config/colors";

const Student = ({ ...props }) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const getValues = useParams();
  const classId =
    !empty(getValues) && !empty(getValues.classId) ? getValues.classId : "";
  const location = useLocation();
  //ref
  const toastTR = useRef(null);
  // states
  const [studentData, setStudentData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(50);
  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState("center");
  const [selectedStudentName, setSelectedStudentName] = useState("");

  useEffect(() => {
    // fetch student
    try {
      getStudents();
    } catch (error) {
      responseDailog(
        "error",
        "Something went wrong",
        "Failed to load student. Please try again later."
      );
      if (!empty(location) && !empty(location.state)) {
        navigate(null, {
          replace: true,
          state: { studentAdded: false, studentUpdated: false },
        });
      }
    }
  }, [first, rows, search, classId]);

  //alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR?.current?.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  // function to get all student
  const getStudents = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const page = first / rows;
      let response;
      if (!empty(classId)) {
        response = await studentApi.getStudentsInClass(
          schoolId,
          page,
          rows,
          search,
          classId,
          "list"
        );
      } else {
        response = await studentApi.getStudents(
          schoolId,
          page,
          rows,
          search,
          "list"
        );
      }
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setStudentData([]);
      } else {
        setStudentData(response_data.response);
        setTotalRecords(!empty(response_data.count) ? response_data.count : 0);
      }

      // check if a new student was added
      if (location.state !== null) {
        const paramState = !empty(location.state) ? location.state : {};
        const studentAdded = !empty(paramState.studentAdded)
          ? paramState.studentAdded
          : false;
        const studentUpdated = !empty(paramState.studentUpdated)
          ? paramState.studentUpdated
          : false;
        if (studentAdded === true || studentUpdated === true) {
          const actionType = studentAdded ? "added" : "updated";
          responseDailog(
            "success",
            "Success",
            `Student ${actionType} successfully!`
          );
          if (!empty(location) && !empty(location.state)) {
            navigate(null, {
              replace: true,
              state: { studentAdded: false, studentUpdated: false },
            });
          }
        }
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong.`);
    } finally {
      setIsLoading(false);
    }
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };
  const onSearchChange = (event) => {
    setSearchValue(event.target.value);
    setFirst(0);
  };

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      setSearch(event.target.value);
      setFirst(0);
    }
  };

  const footerContent = (
    <div>
      <Button
        label="Close"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="p-button-text"
        style={{
          color: colors.danger,
          borderColor: colors.danger,
          cursor: "pointer",
        }}
      />
    </div>
  );

  const show = (position) => {
    setPosition(position);
    setVisible(true);
  };
  // open payment alert
  const openPaymentAlert = (data) => {
    if (!empty(data)) {
      const studentFirstName = !empty(data.firstName) ? data.firstName : "";
      const studentLastName = !empty(data.lastName) ? data.lastName : "";
      setSelectedStudentName(`${studentLastName} ${studentFirstName}`);
    }
    show("top");
  };

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="tableCard">
            {/*  header start */}
            <MainHeader title="Students" />
            {/* end of header */}

            {/* table start */}
            {!isLoading ? (
              <StudentsTable
                students={studentData}
                onPageChange={onPageChange}
                rows={rows}
                totalRecords={totalRecords}
                first={first}
                search={searchValue}
                onSearchChange={onSearchChange}
                onKeyDown={onKeyDown}
                openPaymentAlert={openPaymentAlert}
              />
            ) : (
              <TableLoading />
            )}
            {/* table end  */}
          </div>
        </main>
      </AppWrapper>
      <Dialog
        header="Outstanding Balance"
        visible={visible}
        position={position}
        style={{ width: "50vw" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
        footer={footerContent}
        draggable={false}
        resizable={false}
      >
        <p className="m-0">
          {selectedStudentName ? (
            <>
              <strong>{selectedStudentName}</strong>'s account is on hold due to
              an outstanding balance. To restore access to profile details,
              please advise the student to contact the finance office and make
              the neccesary payments.
            </>
          ) : (
            <>
              This student's account is on hold due to an outstanding balance.
              To restore access to profile details, please advise the student to
              contact the finance office and make the neccesary payments.
            </>
          )}
        </p>
      </Dialog>
      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default Student;
