import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { AuthContext } from "../Root/ProtectedRoute";

// css
import "../students/Students.css";

// api
import sessionApi from "../../api/Session";

//components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import SessionTable from "../../components/tables/primeTable/session/SessionTable";
import { Toast } from "primereact/toast";
import TableLoading from "../../components/skeleton/TableLoading";

const Sessions = ({ ...props }) => {
  const { user, token } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  //ref
  const toastTR = useRef(null);
  // states
  const [sessionData, setsessionData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // fetch sessions
    try {
      getSessions();
    } catch (error) {
      responseDailog(
        "error",
        "Something went wrong",
        "Failed to load sessions. Please try again later."
      );
      if (!empty(location) && !empty(location.state)) {
        navigate(null, { replace: true, state: { sessionAdded: false } });
      }
    }
  }, []);

  //alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR?.current?.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  const getSessions = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await sessionApi.getSessions(schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setsessionData([]);
      } else {
        setsessionData(response_data.response);
      }

      // check if a new session was added
      const paramState = !empty(location.state) ? location.state : {};
      const sessionAdded = !empty(paramState.sessionAdded)
        ? paramState.sessionAdded
        : false;
      const sessionUpdated = !empty(paramState.sessionUpdated)
        ? paramState.sessionUpdated
        : false;
      if (sessionAdded === true || sessionUpdated === true) {
        const actionType = sessionAdded ? "added" : "updated";
        responseDailog(
          "success",
          "Success",
          `Session ${actionType} successfully!`
        );
        if (!empty(location) && !empty(location.state)) {
          navigate(null, {
            replace: true,
            state: { sessionAdded: false, sessionUpdated: false },
          });
        }
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong!`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="tableCard">
            {/*  header start */}
            <MainHeader title="Sessions" />
            {/* end of header */}

            {/* table start */}
            {!isLoading ? (
              <SessionTable sessions={sessionData} />
            ) : (
              <TableLoading cols={2} rows={20} />
            )}
            {/* table end  */}
          </div>
        </main>
      </AppWrapper>
      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default Sessions;
