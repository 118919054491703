import client from "./Client";

const getStaffData = (userId) =>
  client.get(`/staff/details/${encodeURIComponent(userId)}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

export default {
  getStaffData,
};
