import "./Sidebar.css";
import { FaTh, FaUser, FaUserLock } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useContext } from "react";

// hooks
import { AuthContext } from "../../screens/Root/ProtectedRoute";
import { empty, isString } from "../../Utilities/utils";

// images
import appLogo from "../../assets/logo/blue-logo.png";

const SettingsSidebar = ({ sidebarOpen, toggleSidebar }) => {
  const { user } = useContext(AuthContext);
  const userAvatar = !empty(user) && !empty(user.avatar) ? user.avatar : "";
  const fullName =
    !empty(user) && !empty(user.fullName) && isString(user.fullName)
      ? user.fullName.toUpperCase()
      : "";
  const title =
    !empty(user) && !empty(user.title) && isString(user.title)
      ? user.title.toUpperCase()
      : "";
  const schoolAvatar =
    !empty(user.schoolData) && !empty(user.schoolData.schoolAvatar)
      ? user.schoolData.schoolAvatar
      : "";
  const reverseSidebar = () => {
    toggleSidebar();
  };

  return (
    <div className={sidebarOpen ? "sidebar_responsive" : ""} id="sidebar">
      <div className="sidebar_title">
        <div className="flex flex-center direction-column align-center sidebar-image-container">
          <img
            className="sidebar-header-image"
            src={
              !empty(userAvatar)
                ? userAvatar
                : !empty(schoolAvatar)
                ? schoolAvatar
                : appLogo
            }
            alt="logo"
          />
          <p className="text-black text-center">
            {title} {fullName} <br />
            (Staff)
          </p>
        </div>
      </div>

      <div className="sidebar_menu">
        {/* sidebar link */}
        <div className="separator">Home</div>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/dashboard"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaTh className="sidebar_link_icon" />
              <span className="sidebar_link_text">Dashboard</span>
            </div>
          )}
        </NavLink>

        <div className="separator">Settings</div>
        {/* sidebar link */}
        {/* sidebar link */}
        <NavLink
          onClick={() => reverseSidebar()}
          to="/settings"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaUserLock className="sidebar_link_icon" />
              <div className="sidebar_link_text">Update Password</div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/profile"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaUser className="sidebar_link_icon" />
              <div className="sidebar_link_text">Profile</div>
            </div>
          )}
        </NavLink>
      </div>
    </div>
  );
};

export default SettingsSidebar;
