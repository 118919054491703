import { useState } from "react";
import { Routes, Route } from "react-router-dom";

// screens
import Dashboard from "../dashboard/Dashboard";
import ErrorBoundary from "../../components/error/AppErrorBoundary";
import ProtectedRoute from "./ProtectedRoute";
import ForgotPassword from "../authentication/ForgotPassword";
import Signin from "../authentication/Signin";
import Students from "../students/Students";
import SchoolStaff from "../staff/SchoolStaff";
import Terms from "../term/Terms";
import Sessions from "../session/Sessions";
import SchoolClasses from "../classes/SchoolClasses";
import Subjects from "../subjects/Subjects";
import ClassProfile from "../classes/ClassProfile";
import StudentsPerformance from "../classes/StudentsPerformance";
import StaffProfile from "../staff/StaffProfile";
import StudentsProfile from "../students/StudentsProfile";
import SetAssessment from "../assessment/SetAssessment";
import RecordAssessment from "../assessment/RecordAssessment";
import Settings from "../settings/Settings";
import SubjectsToAssess from "../assessment/SubjectsToAssess";
import ReportSheet from "../assessment/ReportSheet";
import ResultChecker from "../assessment/ResultCheck";
import Index from "../Index/Index";
import LogOut from "../../Utilities/logout";
import ResetPassword from "../authentication/ResetPassword";
import NotFound from "../error_pages/NotFound";
import UnauthorizedPage from "../error_pages/UnauthorizedPage";
import DisplayReportSheet from "../assessment/DisplayReportSheet";
import PositionAssessment from "../assessment/PositionAssessment";
import Profile from "../settings/Profile";

const Root = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="container">
      <Routes>
        {/* authentication. Non protected */}
        <Route path="/" element={<Signin />} errorElement={<ErrorBoundary />} />
        <Route element={<Index />} errorElement={<ErrorBoundary />} path="/" />
        <Route
          element={<LogOut />}
          errorElement={<ErrorBoundary />}
          path="/logout"
        />
        <Route
          element={<ForgotPassword />}
          errorElement={<ErrorBoundary />}
          path="/forgot-password"
        />
        <Route
          element={<ResetPassword />}
          errorElement={<ErrorBoundary />}
          path="/reset-password"
        />
        {/* protected route */}
        <Route element={<ProtectedRoute />} errorElement={<ErrorBoundary />}>
          <Route
            element={
              <Dashboard
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            path="/dashboard"
          />
          <Route
            path="/students/class/:classId?"
            element={
              <Students
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/staff/:staffId/profile"
            element={
              <StaffProfile
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/student/:studentId/profile"
            element={
              <StudentsProfile
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/student/:studentId/report-sheet/session/:sessionId/term/:termId/class/:classId"
            element={
              <DisplayReportSheet
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/assessment/set"
            element={
              <SetAssessment
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/assessment/position"
            element={
              <PositionAssessment
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/assessment/result-checker"
            element={
              <ResultChecker
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/assessment/report-sheet"
            element={
              <ReportSheet
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/assessment/subjects"
            element={
              <SubjectsToAssess
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/profile"
            element={
              <Profile
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/assessment/record"
            element={
              <RecordAssessment
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/settings"
            element={
              <Settings
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/students"
            element={
              <Students
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/class/:classId/students/performance"
            element={
              <StudentsPerformance
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/staff"
            element={
              <SchoolStaff
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/sessions"
            element={
              <Sessions
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/terms"
            element={
              <Terms sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/classes"
            element={
              <SchoolClasses
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/classes/:classId/profile"
            element={
              <ClassProfile
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
          <Route
            path="/subjects"
            element={
              <Subjects
                sidebarOpen={sidebarOpen}
                toggleSidebar={toggleSidebar}
              />
            }
            errorElement={<ErrorBoundary />}
          />
        </Route>
        {/* authentication. Non protected */}
        <Route path="*" element={<NotFound />} />
        <Route path="/not-found" element={<NotFound />} />
        <Route path="/unauthorized" element={<UnauthorizedPage />} />
      </Routes>
    </div>
  );
};

export default Root;
