import { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { Toast } from "primereact/toast";

// css
import "../students/Students.css";
import "../dashboard/Dashboard.css";
import "./Staff.css";

// api
import staffApi from "../../api/Staff";

// components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import Card from "../../components/card/Card";
import StatCard from "../../components/statcard/StatCard";
import {
  FaBookReader,
  FaChartArea,
  FaChartLine,
  FaStar,
  FaUserGraduate,
} from "react-icons/fa";
import { useContext } from "react";
import { AuthContext } from "../Root/ProtectedRoute";
import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";
import Underline from "../../components/others/Underline";

const StaffProfile = ({ ...props }) => {
  const { user } = useContext(AuthContext);
  const [staffData, setStaffData] = useState({});
  const [numberOfClasses, setNumberOfClasses] = useState(0);
  const [numOfSubjects, setNumOfSubjects] = useState(0);
  const [assignedSubjects, setAssignedSubjects] = useState([]);
  const [assignedClasses, setAssignedClasses] = useState([]);
  const [assessmentPercentage, setAssessmentPercentage] = useState(0);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); // (WIP)
  const { staffId } = useParams();
  const toastTR = useRef(null);

  if (!staffId) {
    navigate("/404");
  }

  // alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR?.current?.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  useEffect(() => {
    getStaffDetails();
  }, [staffId]);

  const getStaffDetails = async () => {
    setIsLoading(true);
    try {
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await staffApi.getStaffProfile(staffId, schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data?.response) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "Something went wrong!"
            : "Something went wrong"
        );
      }
      const staffDetails =
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : {};
      const classes =
        !empty(response_data) && !empty(staffDetails.assignedClasses)
          ? staffDetails.assignedClasses
          : [];
      const subjects =
        !empty(staffDetails) && !empty(staffDetails.assignedSubjects)
          ? staffDetails.assignedSubjects
          : [];
      setAssignedSubjects(subjects);
      setAssignedClasses(classes);
      setNumberOfClasses(classes.length);
      setNumOfSubjects(subjects.length);
      setStaffData(staffDetails);
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const firstName =
    !empty(staffData) && !empty(staffData.firstName) ? staffData.firstName : "";
  const lastName =
    !empty(staffData) && !empty(staffData.lastName) ? staffData.lastName : "";
  const firstNameInitial = firstName.charAt(0);
  const lastNameInitial = lastName.charAt(0);
  const middleName =
    !empty(staffData) && !empty(staffData.middleName)
      ? staffData.middleName
      : "";
  const gender =
    !empty(staffData) && !empty(staffData.gender) ? staffData.gender : "";
  const title =
    !empty(staffData) && !empty(staffData.title) ? staffData.title : "";
  const address =
    !empty(staffData) && !empty(staffData.address) ? staffData.address : "";
  const stateOfResidence =
    !empty(staffData) && !empty(staffData.stateOfResidence)
      ? staffData.stateOfResidence
      : "";
  const lgaOfResidence =
    !empty(staffData) && !empty(staffData.lgaOfResidence)
      ? staffData.lgaOfResidence
      : "";
  const stateOfOrigin =
    !empty(staffData) && !empty(staffData.stateOfOrigin)
      ? staffData.stateOfOrigin
      : "";
  const lgaOfOrigin =
    !empty(staffData) && !empty(staffData.lgaOfOrigin)
      ? staffData.lgaOfOrigin
      : "";
  const nationality =
    !empty(staffData) && !empty(staffData.nationality)
      ? staffData.nationality
      : "";
  const religion =
    !empty(staffData) && !empty(staffData.religion) ? staffData.religion : "";
  const active =
    !empty(staffData) && !empty(staffData.active) ? staffData.active : "";
  const phoneNumber1 =
    !empty(staffData) && !empty(staffData.phoneNumber1)
      ? staffData.phoneNumber1
      : "";
  const phoneNumber2 =
    !empty(staffData) && !empty(staffData.phoneNumber2)
      ? staffData.phoneNumber2
      : "";
  const email =
    !empty(staffData) && !empty(staffData.email) ? staffData.email : "";
  const qualification =
    !empty(staffData) && !empty(staffData.qualification)
      ? staffData.qualification
      : "";
  const courseStudied =
    !empty(staffData) && !empty(staffData.courseStudied)
      ? staffData.courseStudied
      : "";

  return (
    <>
      <AppWrapper {...props}>
        <main>
          {/* menu header */}
          <MainHeader title="Staff Profile" />
          {/* end menu header */}
          <div className="mt-10 dashboard_container">
            {/* left box */}
            <div id="main_left_box">
              {/* stat box */}
              <div className="top_stat_box">
                <Card
                  children={
                    <StatCard
                      underlineWidth={15}
                      bgColor="#f27c1b"
                      title="Classes"
                      entry={numberOfClasses}
                      icon={<FaUserGraduate className="stat_card_icon" />}
                    />
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    <StatCard
                      underlineWidth={15}
                      bgColor="#633ccd"
                      title="Subjects"
                      entry={numOfSubjects}
                      icon={<FaBookReader className="stat_card_icon" />}
                    />
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    <StatCard
                      underlineWidth={15}
                      bgColor="#e65061"
                      title="Assessment"
                      entry={assessmentPercentage + "%"}
                      icon={<FaChartLine className="stat_card_icon" />}
                    />
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    <StatCard
                      underlineWidth={15}
                      bgColor="#98c38b"
                      title="Attendance"
                      entry="N/A"
                      icon={<FaChartArea className="stat_card_icon" />}
                    />
                  }
                  addStyle="card_adjust_stat"
                />
              </div>
              {/* stat header end */}

              {/* chart */}
              <div className="chart_box">
                <Card
                  children={
                    <>
                      <MainHeader title="Staff Bio-Data" />
                      <div className="dashboard_school_list">
                        <div className="avatar-container">
                          <div className="avatar-box">
                            <Avatar
                              label={lastNameInitial + firstNameInitial}
                              shape="circle"
                              style={{ width: 150, height: 150, fontSize: 70 }}
                              className="p-overlay-badge"
                            >
                              <Badge
                                style={{
                                  position: "absolute",
                                  top: 22,
                                  right: 22,
                                  width: 15,
                                  height: 15,
                                  backgroundColor: "#e65061",
                                }}
                                className="avatar-badge"
                              />
                            </Avatar>
                          </div>
                          <div className="information-container">
                            <div
                              style={{
                                marginTop: 50,
                                fontSize: 20,
                                marginBottom: 15,
                                color: "#633ccd",
                              }}
                            >
                              <strong>Personal Information</strong>
                              <Underline />
                            </div>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">First Name: </strong>
                              <span>{firstName}</span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Last Name: </strong>
                              <span>{lastName}</span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Middle Name: </strong>
                              <span>{middleName}</span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Gender: </strong>
                              <span>{gender}</span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Title: </strong>
                              <span>{title}</span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Address: </strong>
                              <span>{address}</span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">
                                State of Residence:{" "}
                              </strong>
                              <span>{stateOfResidence}</span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">
                                L.G.A of Residence:{" "}
                              </strong>
                              <span>{lgaOfResidence}</span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">
                                State of Origin:{" "}
                              </strong>
                              <span>{stateOfOrigin}</span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">
                                L.G.A of Origin:{" "}
                              </strong>
                              <span>{lgaOfOrigin}</span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Nationality: </strong>
                              <span>{nationality}</span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Religion: </strong>
                              <span>{religion}</span>
                            </p>
                            <div
                              style={{
                                marginTop: 30,
                                fontSize: 20,
                                marginBottom: 15,
                                color: "#633ccd",
                              }}
                            >
                              <strong>Contact Information</strong>
                              <Underline />
                            </div>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">
                                Phone Number 1:{" "}
                              </strong>
                              <span>{phoneNumber1}</span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">
                                Phone Number 2:{" "}
                              </strong>
                              <span>{phoneNumber2}</span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Email: </strong>
                              <span>{email}</span>
                            </p>
                            <div
                              style={{
                                marginTop: 30,
                                fontSize: 20,
                                marginBottom: 15,
                                color: "#633ccd",
                              }}
                            >
                              <strong>Academic Information</strong>
                              <Underline />
                            </div>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">Qualification: </strong>
                              <span>{qualification}</span>
                            </p>
                            <p className="mb-10 flex space-between bio-data-item">
                              <strong className="fs-16">
                                Course Studied:{" "}
                              </strong>
                              <span>{courseStudied}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                  addStyle="student_list_box"
                />
              </div>
              {/* end chart */}

              {/*  */}
            </div>
            {/* end of left box */}

            {/* right box */}
            <div id="main_right_box">
              <div className="main_right_boxes">
                <Card
                  children={
                    <>
                      <MainHeader title="Assigned Subjects" />
                      <div className="mt-10">
                        {assignedSubjects.map((item) => {
                          return (
                            <p
                              key={
                                !empty(item) && !empty(item._id) ? item._id : 0
                              }
                              className="mb-10 flex space-between bio-data-item"
                            >
                              <span>
                                <FaStar
                                  style={{ marginRight: 8, fontSize: 12 }}
                                  color="#DAA520"
                                />
                                {!empty(item) && !empty(item.title)
                                  ? item.title
                                  : ""}
                              </span>
                            </p>
                          );
                        })}
                      </div>
                    </>
                  }
                  addStyle="card_adjust"
                />
                <Card
                  children={
                    <>
                      <MainHeader title="Assigned Class(es)" />
                      <div className="mt-10">
                        {assignedClasses.map((item) => {
                          return (
                            <p
                              key={
                                !empty(item) && !empty(item._id) ? item._id : 0
                              }
                              className="mb-10 flex space-between bio-data-item"
                            >
                              <span>
                                <FaStar
                                  style={{
                                    marginRight: 8,
                                    fontSize: 12,
                                  }}
                                  color="#DAA520"
                                />
                                {!empty(item) && !empty(item.title) ? (
                                  <NavLink
                                    style={{
                                      textDecoration: "none",
                                      color: "#633ccd",
                                    }}
                                    to={`/classes/${
                                      !empty(item) && !empty(item._id)
                                        ? item._id + "/profile"
                                        : ""
                                    }`}
                                  >
                                    {item.title}
                                  </NavLink>
                                ) : (
                                  ""
                                )}
                              </span>
                            </p>
                          );
                        })}
                      </div>
                    </>
                  }
                  addStyle="card_adjust"
                />
              </div>
            </div>
            {/* end of right box */}
          </div>
        </main>
        <Toast ref={toastTR} position="bottom-left" />
      </AppWrapper>
    </>
  );
};

export default StaffProfile;
