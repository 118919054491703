import React from "react";
import Navbar from "../navbar/Navbar";
import SettingsSidebar from "../sidebar/SettingsSidebar";

function SettingsWrapper({ children, sidebarOpen, toggleSidebar }) {
  return (
    <>
      <Navbar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {children}
      <SettingsSidebar
        sidebarOpen={sidebarOpen}
        toggleSidebar={toggleSidebar}
      />
    </>
  );
}

export default SettingsWrapper;
