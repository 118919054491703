import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { empty, prepareResponseData } from "../../Utilities/utils";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

// css
import "../students/Students.css";
import "../dashboard/Dashboard.css";

// api
import classApi from "../../api/Classes";

// components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import Card from "../../components/card/Card";
import { useContext } from "react";
import { AuthContext } from "../Root/ProtectedRoute";

const StudentsPerformance = ({ ...props }) => {
  const { user, token } = useContext(AuthContext);
  const [studentsLoading, setStudentsLoading] = useState(false); //(WIP)
  const [classData, setClassData] = useState({});
  const [students, setStudents] = useState([]);
  const [sessionId, setSessionId] = useState(""); // for stats if selected (WIP)
  const [termId, setTermId] = useState(0); // // for stats if selected (WIP)

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); // (WIP)
  const { classId } = useParams();
  const toastTR = useRef(null);

  if (!classId) {
    navigate("/404");
  }

  // alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR?.current?.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  let authorizedClasses = [];
  let isAuthorized = true;

  // check if user is authorized to view class details
  const userAuthorizations =
    !empty(user) && !empty(user.authorization) ? user.authorization : [];
  const classesObj = userAuthorizations.find((obj) =>
    obj.hasOwnProperty("classes")
  );
  authorizedClasses = classesObj ? classesObj.classes : [];
  if (
    !empty(authorizedClasses) &&
    (authorizedClasses.includes("All") || authorizedClasses.includes(classId))
  ) {
    isAuthorized = true;
  }

  useEffect(() => {
    if (isAuthorized) {
      getClassDetails();
      getStudentsInClassPerformance();
    }
  }, [classId]);

  const getClassDetails = async () => {
    setIsLoading(true);
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getSingleClass(classId, schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data?.response) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "Something went wrong!"
            : "Something went wrong"
        );
      }
      setClassData(
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : {}
      );
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const getStudentsInClassPerformance = async () => {
    setStudentsLoading(true);
    try {
      const schoolId = !empty(user) && !empty(user._id) ? user._id : "";
      const response = await classApi.getStudentsInClassPerformance(
        classId,
        schoolId,
        termId,
        sessionId,
        null
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data?.response) {
        return;
      }
      setStudents(
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : []
      );
    } catch (error) {
      responseDailog("error", "Students", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const title =
    !empty(classData) && !empty(classData.title) ? classData.title : "";
  const active =
    !empty(classData) && !empty(classData.active) ? classData.active : "";

  const performanceBodyTemplate = (rowData) => {
    const score =
      !empty(rowData) && !empty(rowData.performanceScore)
        ? rowData.performanceScore
        : 0;

    let score_tag = "";
    if (score >= 70) {
      score_tag = <span style={{ color: "green" }}>{score}%</span>;
    } else if (score >= 60 && score <= 69.9) {
      score_tag = <span style={{ color: "#633ccd" }}>{score}%</span>;
    } else if (score >= 50 && score <= 59.9) {
      score_tag = <span style={{ color: "orange" }}>{score}%</span>;
    } else if (score >= 45 && score <= 49.9) {
      score_tag = <span style={{ color: "gray" }}>{score}%</span>;
    } else {
      score_tag = <span style={{ color: "red" }}>{score}%</span>;
    }

    return score_tag;
  };

  return (
    <>
      <AppWrapper {...props}>
        <main>
          {active && isAuthorized ? (
            <>
              {/* menu header */}
              <MainHeader title={title + " students` performance"} />
              {/* end menu header */}
              <div
                className="mt-10"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div className="chart_box">
                  <Card
                    children={
                      <>
                        <div className="dashboard_school_list">
                          <DataTable
                            value={students}
                            tableStyle={{ minWidth: "30rem" }}
                          >
                            <Column field="regNo" header="Reg. No."></Column>
                            <Column
                              field="fullName"
                              header="Full Name"
                            ></Column>
                            <Column
                              field="performanceScore"
                              header="Performance"
                              body={performanceBodyTemplate}
                            ></Column>
                          </DataTable>
                        </div>
                      </>
                    }
                    addStyle="student_list_box"
                  />
                </div>
              </div>
            </>
          ) : (
            <div>
              {/* you don't have access to this class or it is inactive */}
            </div>
          )}
        </main>
        <Toast ref={toastTR} position="bottom-left" />
      </AppWrapper>
    </>
  );
};

export default StudentsPerformance;
