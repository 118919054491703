import { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  empty,
  isArray,
  prepareResponseData,
  reIndex,
} from "../../Utilities/utils";
import { Toast } from "primereact/toast";

// css
import "../students/Students.css";
import "../dashboard/Dashboard.css";
import "../staff/Staff.css";

// api
import studentApi from "../../api/Student";
import termApi from "../../api/Terms";
import sessionApi from "../../api/Session";

// components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import Card from "../../components/card/Card";
import StatCard from "../../components/statcard/StatCard";
import {
  FaBookReader,
  FaChartArea,
  FaChartLine,
  FaEdit,
  FaExclamationTriangle,
  FaStar,
  FaUserGraduate,
} from "react-icons/fa";
import { useContext } from "react";
import { AuthContext } from "../Root/ProtectedRoute";
import { Avatar } from "primereact/avatar";
import BoxLoading from "../../components/skeleton/BoxLoading";
import ItemLoading from "../../components/skeleton/ItemLoading";
import TableLoading from "../../components/skeleton/TableLoading";
import FullPageLoader from "../../components/loader/FullPageLoader";

const StudentsProfile = ({ ...props }) => {
  const { user } = useContext(AuthContext);
  const [studentData, setStudentData] = useState({});
  const [classTitle, setClassTitle] = useState("");
  const [numOfSubjects, setNumOfSubjects] = useState(0);
  const [assignedSubjects, setAssignedSubjects] = useState([]);
  const [assessmentPercentage, setAssessmentPercentage] = useState(0);
  const [percentageLoading, setPercentageLoading] = useState(0);
  const [studentReports, setStudentReports] = useState([]);

  const [avatar, setAvatar] = useState(
    !empty(studentData) && !empty(studentData.avatar) ? studentData.avatar : ""
  );
  const fullName = !empty(user) && !empty(user.fullName) ? user.fullName : "";

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); // (WIP)
  const { studentId } = useParams();
  const toastTR = useRef(null);

  if (!studentId) {
    navigate("/404");
  }

  // alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR.current.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  useEffect(() => {
    getStudentDetails();
  }, []);

  useEffect(() => {
    getStudentPercentage();
  }, [studentId, user]);

  const getReports = async (reports) => {
    try {
      setStudentReports([]);
      if (!empty(reports) && isArray(reports)) {
        const schoolId =
          !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
        const termResponse = await termApi.getTerms(schoolId);
        const response_data = prepareResponseData(termResponse);
        let reIndexedTerms = {};
        let reIndexedSessions = {};
        if (!empty(response_data) && !empty(response_data.response)) {
          reIndexedTerms =
            !empty(response_data.response) && isArray(response_data.response)
              ? reIndex(response_data.response, "_id")
              : {};
        }
        const sessionResponse = await sessionApi.getSessions(schoolId);
        const session_response_data = prepareResponseData(sessionResponse);
        if (
          !empty(session_response_data) &&
          !empty(session_response_data.response)
        ) {
          reIndexedSessions =
            !empty(session_response_data.response) &&
            isArray(session_response_data.response)
              ? reIndex(session_response_data.response, "_id")
              : {};
        }

        const _reports = [];
        for (let i = 0; i < reports.length; i++) {
          const termId =
            !empty(reports[i]) && !empty(reports[i].termId)
              ? reports[i].termId
              : "";
          const sessionId =
            !empty(reports[i]) && !empty(reports[i].sessionId)
              ? reports[i].sessionId
              : "";

          if (!empty(reIndexedSessions[sessionId])) {
            const title = !empty(reIndexedSessions[sessionId].title)
              ? reIndexedSessions[sessionId].title
              : "";
            reports[i].sessionTitle = title;
          }
          if (!empty(reIndexedTerms[termId])) {
            const title = !empty(reIndexedTerms[termId].title)
              ? reIndexedTerms[termId].title
              : "";
            reports[i].termTitle = title;
          }

          if (!empty(reports[i].termTitle) && !empty(reports[i].sessionTitle)) {
            _reports.push(reports[i]);
          }
        }
        _reports.sort((a, b) => {
          const paramA = a.termTitle + a.sessionTitle;
          const paramB = b.termTitle + b.sessionTitle;
          if (paramA < paramB) {
            return -1;
          }
          if (paramA > paramB) {
            return 1;
          }
          return 0;
        });
        setStudentReports(_reports);
      }
    } catch (error) {}
  };

  const getStudentPercentage = async () => {
    setPercentageLoading(true);
    try {
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await studentApi.getStudentPercentage(
        studentId,
        schoolId
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return;
      }
      setAssessmentPercentage(
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : 0
      );
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setPercentageLoading(false);
    }
  };

  const getStudentDetails = async () => {
    setIsLoading(true);
    try {
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await studentApi.getStudentProfile(studentId, schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        navigate(-1);
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data.response)
            ? typeof response_data.response === "string"
              ? response_data.response
              : "Something went wrong!"
            : "Failed to fetch student details"
        );
      }
      const studentDetails =
        !empty(response_data) && !empty(response_data.response)
          ? response_data.response
          : {};
      const graduated =
        !empty(response_data) && !empty(studentDetails.graduationClassId)
          ? true
          : false;
      const title =
        !empty(response_data) && !empty(studentDetails.classTitle)
          ? studentDetails.classTitle
          : graduated
          ? "Graduated"
          : "N/A";
      const subjects =
        !empty(studentDetails) && !empty(studentDetails.assignedSubjects)
          ? studentDetails.assignedSubjects
          : [];
      const reports =
        !empty(studentDetails) && !empty(studentDetails.reports)
          ? studentDetails.reports
          : [];
      setAvatar(
        !empty(studentDetails) && !empty(studentDetails.avatar)
          ? studentDetails.avatar
          : ""
      );
      setAssignedSubjects(subjects);
      setClassTitle(title);
      setNumOfSubjects(subjects.length);
      getReports(reports);
      return setStudentData(studentDetails);
    } catch (error) {
      responseDailog("error", "Error Alert", "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const firstName =
    !empty(studentData) && !empty(studentData.firstName)
      ? studentData.firstName
      : "";
  const lastName =
    !empty(studentData) && !empty(studentData.lastName)
      ? studentData.lastName
      : "";
  const firstNameInitial = firstName.charAt(0);
  const lastNameInitial = lastName.charAt(0);
  const middleName =
    !empty(studentData) && !empty(studentData.middleName)
      ? studentData.middleName
      : "";
  const gender =
    !empty(studentData) && !empty(studentData.gender) ? studentData.gender : "";
  const regNo =
    !empty(studentData) && !empty(studentData.regNo) ? studentData.regNo : "";
  const address =
    !empty(studentData) && !empty(studentData.address)
      ? studentData.address
      : "";
  const stateOfResidence =
    !empty(studentData) && !empty(studentData.stateOfResidence)
      ? studentData.stateOfResidence
      : "";
  const lgaOfResidence =
    !empty(studentData) && !empty(studentData.lgaOfResidence)
      ? studentData.lgaOfResidence
      : "";
  const stateOfOrigin =
    !empty(studentData) && !empty(studentData.stateOfOrigin)
      ? studentData.stateOfOrigin
      : "";
  const lgaOfOrigin =
    !empty(studentData) && !empty(studentData.lgaOfOrigin)
      ? studentData.lgaOfOrigin
      : "";
  const nationality =
    !empty(studentData) && !empty(studentData.nationality)
      ? studentData.nationality
      : "";
  const religion =
    !empty(studentData) && !empty(studentData.religion)
      ? studentData.religion
      : "";
  const dateOfBirth =
    !empty(studentData) && !empty(studentData.dateOfBirth)
      ? studentData.dateOfBirth
      : "";
  const phoneNumber =
    !empty(studentData) && !empty(studentData.phoneNumber)
      ? studentData.phoneNumber
      : "";
  const email =
    !empty(studentData) && !empty(studentData.email) ? studentData.email : "";
  const previousSchool =
    !empty(studentData) && !empty(studentData.previous_school)
      ? studentData.previous_school
      : "";
  const qrCodePath =
    !empty(studentData) && !empty(studentData.qrCodePath)
      ? studentData.qrCodePath
      : "";
  const hasPaid =
    !empty(studentData) && !empty(studentData.paid) ? studentData.paid : "No";
  const graduated =
    !empty(studentData) && !empty(studentData.graduationClassId) ? true : false;

  return (
    <>
      <AppWrapper {...props}>
        <main>
          {/* menu header */}
          <MainHeader title="Student Profile" />
          {/* end menu header */}
          <div
            className="mt-10"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {/* left box */}
            <div id="main_left_box">
              {/* stat box */}
              <div className="top_stat_box">
                <Card
                  children={
                    !isLoading ? (
                      <StatCard
                        underlineWidth={15}
                        bgColor="#f27c1b"
                        title="Class Title"
                        entry={classTitle}
                        icon={<FaUserGraduate className="stat_card_icon" />}
                      />
                    ) : (
                      <BoxLoading />
                    )
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    !isLoading ? (
                      <StatCard
                        underlineWidth={15}
                        bgColor="#633ccd"
                        title="Subjects"
                        entry={numOfSubjects}
                        icon={<FaBookReader className="stat_card_icon" />}
                      />
                    ) : (
                      <BoxLoading />
                    )
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    !percentageLoading ? (
                      <StatCard
                        underlineWidth={15}
                        bgColor="#e65061"
                        title="Assessment"
                        entry={assessmentPercentage + "%"}
                        icon={<FaChartLine className="stat_card_icon" />}
                      />
                    ) : (
                      <BoxLoading />
                    )
                  }
                  addStyle="card_adjust_stat"
                />
                <Card
                  children={
                    <StatCard
                      underlineWidth={15}
                      bgColor="#98c38b"
                      title="Attendance"
                      entry="N/A"
                      icon={<FaChartArea className="stat_card_icon" />}
                    />
                  }
                  addStyle="card_adjust_stat"
                />
              </div>
              {/* stat header end */}

              {/* chart */}
              <div className="chart_box">
                <Card
                  children={
                    <>
                      <MainHeader
                        title="Student Bio-Data"
                        rightIcon={
                          hasPaid === "Yes" ? (
                            <NavLink to={`/student/${studentId}/update`}>
                              <FaEdit
                                style={{ color: "#4338CA", cursor: "pointer" }}
                              />
                            </NavLink>
                          ) : null
                        }
                      />
                      <div className="dashboard_school_list">
                        <div className="avatar-container flex">
                          <div className="staff-avatar-container">
                            <p className="fs-20 bold">{fullName}</p>
                            {!empty(avatar) ? (
                              <Avatar
                                image={avatar}
                                shape="circle"
                                style={{
                                  width: 200,
                                  height: 200,
                                  padding: 10,
                                }}
                                className="mb-10"
                              ></Avatar>
                            ) : (
                              <Avatar
                                label={lastNameInitial + firstNameInitial}
                                shape="circle"
                                style={{
                                  width: 150,
                                  height: 150,
                                  fontSize: 70,
                                }}
                              ></Avatar>
                            )}
                            {hasPaid !== "Yes" && !graduated && (
                              <>
                                <div className="mt-10 assessment_not_paid">
                                  <FaExclamationTriangle
                                    color="red"
                                    size={25}
                                  />
                                  <p>
                                    This student has an outstanding portal levy
                                  </p>
                                </div>
                              </>
                            )}
                          </div>

                          <div className="personal-info-block">
                            <div className="personal-info-list">
                              <div className="profile-title">
                                Registration Number:{" "}
                              </div>
                              <div className="profile-value">
                                {!isLoading ? regNo : <ItemLoading />}
                              </div>
                            </div>
                            <div className="personal-info-list">
                              <div className="profile-title">First Name: </div>
                              <div className="profile-value">
                                {!isLoading ? firstName : <ItemLoading />}
                              </div>
                            </div>
                            <div className="personal-info-list">
                              <div className="profile-title">Last Name: </div>
                              <div className="profile-value">
                                {!isLoading ? lastName : <ItemLoading />}
                              </div>
                            </div>
                            <div className="personal-info-list">
                              <div className="profile-title">Middle Name: </div>
                              <div className="profile-value">
                                {!isLoading ? middleName : <ItemLoading />}
                              </div>
                            </div>
                            <div className="personal-info-list">
                              <div className="profile-title">Gender: </div>
                              <div className="profile-value">
                                {!isLoading ? gender : <ItemLoading />}
                              </div>
                            </div>
                          </div>
                          <div className="personal-info-block">
                            <div className="personal-info-list">
                              <div className="profile-title">Address: </div>
                              <div className="profile-value">
                                {!isLoading ? address : <ItemLoading />}
                              </div>
                            </div>
                            <div className="personal-info-list">
                              <div className="profile-title">
                                Phone Number:{" "}
                              </div>
                              <div className="profile-value">
                                {!isLoading ? phoneNumber : <ItemLoading />}
                              </div>
                            </div>
                            <div className="personal-info-list">
                              <div className="profile-title">Email: </div>
                              <div className="profile-value">
                                {!isLoading ? email : <ItemLoading />}
                              </div>
                            </div>
                            <div className="personal-info-list">
                              <div className="profile-title">
                                Date of Birth:{" "}
                              </div>
                              <div className="profile-value">
                                {!isLoading ? dateOfBirth : <ItemLoading />}
                              </div>
                            </div>
                            <div className="personal-info-list">
                              <div className="profile-title">Nationality: </div>
                              <div className="profile-value">
                                {!isLoading ? nationality : <ItemLoading />}
                              </div>
                            </div>
                            <div className="personal-info-list">
                              <div className="profile-title">
                                State of Residence:{" "}
                              </div>
                              <div className="profile-value">
                                {!isLoading ? (
                                  stateOfResidence
                                ) : (
                                  <ItemLoading />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="other-staff-info-header">
                          <MainHeader title="Other Information" />
                        </div>
                        <div className="flex sm-reverse-direction-vertical other-staff-info col-g-12">
                          <div className="personal-info-block">
                            <div className="personal-info-list">
                              <div className="profile-title">
                                L.G.A of Residence:{" "}
                              </div>
                              <div className="profile-value">
                                {!isLoading ? lgaOfResidence : <ItemLoading />}
                              </div>
                            </div>
                            <div className="personal-info-list">
                              <div className="profile-title">Nationality: </div>
                              <div className="profile-value">
                                {!isLoading ? nationality : <ItemLoading />}
                              </div>
                            </div>
                            <div className="personal-info-list">
                              <div className="profile-title">Regigion: </div>
                              <div className="profile-value">
                                {!isLoading ? religion : <ItemLoading />}
                              </div>
                            </div>
                            <div className="personal-info-list">
                              <div className="profile-title">
                                State of Origin:{" "}
                              </div>
                              <div className="profile-value">
                                {!isLoading ? stateOfOrigin : <ItemLoading />}
                              </div>
                            </div>
                            <div className="personal-info-list">
                              <div className="profile-title">
                                L.G.A of Origin:{" "}
                              </div>
                              <div className="profile-value">
                                {!isLoading ? lgaOfOrigin : <ItemLoading />}
                              </div>
                            </div>
                            <div className="personal-info-list">
                              <div className="profile-title">
                                Previous School:{" "}
                              </div>
                              <div className="profile-value">
                                {!isLoading ? previousSchool : <ItemLoading />}
                              </div>
                            </div>
                          </div>
                          <div className="personal-info-block">
                            <img
                              width={250}
                              height={250}
                              src={qrCodePath}
                              alt="qrcode"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  }
                  addStyle="student_list_box"
                />
              </div>
              {/* end chart */}

              {/*  */}
            </div>
            {/* end of left box */}

            {/* right box */}
            <div id="main_right_box">
              <div className="main_right_boxes">
                {!graduated && (
                  <Card
                    children={
                      <>
                        <MainHeader title="Class Subjects" />
                        <div className="mt-10">
                          {!isLoading ? (
                            assignedSubjects.map((item) => {
                              return (
                                <p
                                  key={
                                    !empty(item) && !empty(item._id)
                                      ? item._id
                                      : 0
                                  }
                                  className="mb-10 flex space-between bio-data-item"
                                >
                                  <span>
                                    <FaStar
                                      color="#DAA520"
                                      style={{ marginRight: 8, fontSize: 12 }}
                                    />
                                    {!empty(item) && !empty(item.title)
                                      ? item.title
                                      : ""}
                                  </span>
                                </p>
                              );
                            })
                          ) : (
                            <TableLoading rows={5} cols={1} header={false} />
                          )}
                        </div>
                      </>
                    }
                    addStyle="card_adjust"
                  />
                )}
                <Card
                  children={
                    <>
                      <MainHeader title="Performance History" />
                      <div className="mt-10">
                        {!isLoading ? (
                          studentReports.map((item, index) => {
                            const termTitle =
                              !empty(item) && !empty(item.termTitle)
                                ? item.termTitle
                                : "";
                            const sessionTitle =
                              !empty(item) && !empty(item.sessionTitle)
                                ? item.sessionTitle
                                : "";
                            const cumulativeScore =
                              !empty(item) && !empty(item.cumulativeScore)
                                ? item.cumulativeScore
                                : "";
                            const average =
                              !empty(item) && !empty(item.average)
                                ? item.average + "%"
                                : "";
                            const grade =
                              !empty(item) && !empty(item.grade)
                                ? item.grade
                                : "";
                            const remark =
                              !empty(item) && !empty(item.remark)
                                ? item.remark
                                : "";
                            const position =
                              !empty(item) && !empty(item.position)
                                ? item.position
                                : "";
                            return (
                              <div
                                key={index}
                                className="mb-10 flex direction-column bio-data-item"
                              >
                                <span>
                                  <FaStar
                                    color="#DAA520"
                                    style={{ marginRight: 8, fontSize: 12 }}
                                  />
                                  {termTitle} ({sessionTitle})
                                </span>
                                <div>
                                  <ul style={{ padding: "8px 35px" }}>
                                    <li style={{ marginBottom: 8 }}>
                                      <span>
                                        <strong>CumulativeScore:&nbsp;</strong>
                                      </span>
                                      <span>{cumulativeScore}</span>
                                    </li>
                                    <li style={{ marginBottom: 8 }}>
                                      <span>
                                        <strong>Average:&nbsp;</strong>
                                      </span>
                                      <span>{average}</span>
                                    </li>
                                    <li style={{ marginBottom: 8 }}>
                                      <span>
                                        <strong>Grade:&nbsp;</strong>
                                      </span>
                                      <span>{grade}</span>
                                    </li>
                                    <li style={{ marginBottom: 8 }}>
                                      <span>
                                        <strong>Position:&nbsp;</strong>
                                      </span>
                                      <span>{position}</span>
                                    </li>
                                    <li style={{ marginBottom: 8 }}>
                                      <span>
                                        <strong>Remark:&nbsp;</strong>
                                      </span>
                                      <span>{remark}</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <TableLoading rows={5} cols={1} header={false} />
                        )}
                      </div>
                    </>
                  }
                  addStyle="card_adjust"
                />
              </div>
            </div>
            {/* end of right box */}
          </div>
        </main>
        <Toast ref={toastTR} position="bottom-left" />
        {isLoading && <FullPageLoader visible={isLoading} />}
      </AppWrapper>
      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default StudentsProfile;
