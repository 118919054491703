import { useContext, useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { Stepper } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";

import {
  convertToSnakeCase,
  empty,
  findGrade,
  isArray,
  isString,
  isUndefined,
  prepareResponseData,
  reIndex,
  validPayload,
} from "../../Utilities/utils";
import { AuthContext } from "../Root/ProtectedRoute";
import { capitalize, isObject } from "lodash";

// css
import "../students/Students.css";
import "./Assessment.css";

// api
import studentApi from "../../api/Student";
import subjectApi from "../../api/Subject";
import classApi from "../../api/Classes";
import assessmentApi from "../../api/Assessment";

//components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import { Toast } from "primereact/toast";
import StudentsTable from "../../components/tables/primeTable/students/StudentsTable";
import { Form, Formik } from "formik";
import InputField from "../../components/form/InputField";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";
import * as Yup from "yup";
import FullPageLoader from "../../components/loader/FullPageLoader";
import TableLoading from "../../components/skeleton/TableLoading";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import colors from "../../config/colors";
import SelectField from "../../components/form/SelectField";

const validationSchema = Yup.object().shape({
  ca1: Yup.string().max(2).optional(),
  ca2: Yup.string().max(2).optional(),
  exam: Yup.string().max(2).optional(),
});

const validationCommentSchema = Yup.object().shape({
  comment: Yup.string().required("This field is required"),
});

const RecordAssessment = ({ ...props }) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const paramState = !empty(location.state) ? location.state : {};
  const classId =
    !empty(paramState) && !empty(paramState.classId) ? paramState.classId : "";
  const subjectId =
    !empty(paramState) && !empty(paramState.subjectId)
      ? paramState.subjectId
      : "";

  //ref
  const toastTR = useRef(null);
  const stepperRef = useRef(null);

  // states
  const [studentData, setStudentData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(50);
  const [search, setSearch] = useState("");
  const [isStepper, setIsStepper] = useState(false);
  const [studentAssessment, setStudentAssessment] = useState([]);
  const [reIndexedSubjects, setReIndexedSubjects] = useState({});
  const [isStudentDialog, setIsStudentDialog] = useState(false);
  const [activeDetails, setActiveDetails] = useState({});
  const [studentReportComment, setStudentReportComment] = useState("");
  const [remarks, setRemarks] = useState([]);
  const [classCategoryId, setClassCategoryId] = useState([]);
  const [defaultCategoryId, setDefaultCategoryId] = useState([]);
  const [scoreGrades, setScoreGrades] = useState([]);
  const [affectiveDomainData, setAffectiveDomainData] = useState([]);
  const [ratingIndicesData, setRatingIndicesData] = useState([]);
  const [psychomotor, setPsychomotor] = useState([]);
  const [studentPsychomotor, setStudentPsychomotor] = useState({});
  const [subjectTitle, setSubjectTitle] = useState("");
  const [classTitle, setClassTitle] = useState("");
  const [studentAffectiveDomain, setStudentAffectiveDomain] = useState({});
  const [initialValues, setInitialValues] = useState({
    ca1: 0,
    ca2: 0,
    exam: 0,
  });
  const [selectedStudentTotal, setSelectedStudentTotal] = useState(0);

  useEffect(() => {
    const loadDependencies = async (reIndexedScoreGrades) => {
      await getSubjectDetails();
      await getClassDetails();
      await getSubjectsInClass();
      await getAffectiveDomains();
      await getPsychomotor();
      await getRatingIndices();
      await setScoreGrades(reIndexedScoreGrades);
    };

    loadDependencies(reIndexedScoreGrades);
  }, [user]);

  //alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR?.current?.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  // school details
  const schoolData =
    !empty(user) && !empty(user.schoolData) && isObject(user.schoolData)
      ? user.schoolData
      : {};
  const sessionId = !empty(schoolData.currentSessionId)
    ? schoolData.currentSessionId
    : "";
  const _scoreGrades =
    !empty(schoolData) && !empty(schoolData.scoreGrades)
      ? schoolData.scoreGrades
      : [];
  const reIndexedScoreGrades =
    !empty(_scoreGrades) && isArray(_scoreGrades)
      ? reIndex(_scoreGrades, "categoryId")
      : {};
  const termId = !empty(schoolData.currentTermId)
    ? schoolData.currentTermId
    : "";
  const currentTermTitle = !empty(schoolData.currentTermTitle)
    ? schoolData.currentTermTitle
    : "";
  const currentSessionTitle = !empty(schoolData.currentSessionTitle)
    ? schoolData.currentSessionTitle
    : "";
  const assessmentMaxScores =
    !empty(schoolData.assessmentMaxScores) &&
    isObject(schoolData.assessmentMaxScores)
      ? schoolData.assessmentMaxScores
      : {};
  const ca1_max = !empty(assessmentMaxScores.ca1_max)
    ? assessmentMaxScores.ca1_max
    : 20;
  const ca2_max = !empty(assessmentMaxScores.ca2_max)
    ? assessmentMaxScores.ca2_max
    : 20;
  const exam_max = !empty(assessmentMaxScores.exam_max)
    ? assessmentMaxScores.exam_max
    : 60;

  useEffect(() => {
    // fetch student
    try {
      if (!empty(classId) && !empty(subjectId)) {
        getStudents();
      } else {
        navigate("/404");
      }
    } catch (error) {
      responseDailog(
        "error",
        "Something went wrong",
        "Please try again later."
      );
    }
  }, [first, rows, search, classId]);

  // function to get all affective domain
  const getAffectiveDomains = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await assessmentApi.getAffectiveDomains(schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setAffectiveDomainData([]);
      } else {
        const data =
          !empty(response_data) &&
          !empty(response_data.response) &&
          isArray(response_data.response)
            ? response_data.response
            : [];
        return setAffectiveDomainData(data);
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong.`);
    } finally {
      setIsLoading(false);
    }
  };

  // function to get all pyschomotor
  const getPsychomotor = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await assessmentApi.getPsychomotor(schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setPsychomotor([]);
      } else {
        const data =
          !empty(response_data) &&
          !empty(response_data.response) &&
          isArray(response_data.response)
            ? response_data.response
            : [];
        setPsychomotor(data);
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong.`);
    } finally {
      setIsLoading(false);
    }
  };

  // function to get all rating indices
  const getRatingIndices = async () => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await assessmentApi.getRatings(schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setRatingIndicesData([]);
      } else {
        const data =
          !empty(response_data) &&
          !empty(response_data.response) &&
          isArray(response_data.response)
            ? response_data.response
            : [];
        setRatingIndicesData(data);
      }
    } catch (error) {
      responseDailog("error", "Error Alert", `Something went wrong.`);
    } finally {
      setIsLoading(false);
    }
  };

  // function to get all student
  const getStudents = async (returnData = false) => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const page =
        isNaN(first / rows) || isUndefined(first / rows) ? 0 : first / rows;
      let response;
      response = await studentApi.getStudentsInClass(
        schoolId,
        isUndefined(page) || isNaN(page) ? 0 : page,
        isUndefined(rows) || isNaN(rows) ? 50 : rows,
        search,
        classId,
        "assessment"
      );
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setStudentData([]);
      } else {
        if (returnData) {
          return response_data.response;
        } else {
          setStudentData(
            !empty(response_data.response) && isArray(response_data.response)
              ? response_data.response
              : []
          );
          setTotalRecords(
            !empty(response_data.count) && parseInt(response_data.count)
              ? response_data.count
              : 0
          );
        }
      }
    } catch (error) {
      responseDailog("error", "Internal Server Error", `Something went wrong.`);
    } finally {
      setIsLoading(false);
    }
  };

  const getSubjectDetails = async () => {
    try {
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await subjectApi.getSingleSubject(subjectId, schoolId);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return setSubjectTitle("");
      } else {
        const data =
          !empty(response_data.response) && isObject(response_data.response)
            ? response_data.response
            : {};
        const subjectTitle = !empty(data.title) ? data.title : "";
        return setSubjectTitle(subjectTitle);
      }
    } catch (error) {
      responseDailog("error", "Internal Server Error", `Something went wrong.`);
    }
  };

  const getClassDetails = async () => {
    try {
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await classApi.getSingleClass(classId, schoolId);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return setClassTitle("");
      } else {
        const data =
          !empty(response_data) &&
          !empty(response_data.response) &&
          isObject(response_data.response)
            ? response_data.response
            : {};
        const _classCategoryId =
          !empty(data) && !empty(data.categoryId) && isString(data.categoryId)
            ? data.categoryId
            : "";
        const _defaultCategoryId =
          !empty(data) &&
          !empty(data.defaultCategoryId) &&
          isString(data.defaultCategoryId)
            ? data.defaultCategoryId
            : "";
        setClassCategoryId(_classCategoryId);
        setDefaultCategoryId(_defaultCategoryId);
        const _remark =
          !empty(reIndexedScoreGrades) &&
          !empty(reIndexedScoreGrades[classCategoryId]) &&
          !empty(reIndexedScoreGrades[classCategoryId].remarks) &&
          isArray(reIndexedScoreGrades[classCategoryId].remarks)
            ? reIndexedScoreGrades[classCategoryId].remarks
            : [];
        const classTitle = !empty(data.title) ? data.title : "";
        setClassTitle(classTitle);
        return setRemarks(_remark);
      }
    } catch (error) {
      responseDailog("error", "Internal Server Error", `Something went wrong.`);
    }
  };

  const getSubjectsInClass = async () => {
    try {
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await classApi.getSubjectsInClass(
        classId,
        schoolId,
        1000
      );
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return setReIndexedSubjects({});
      } else {
        const classSubjects =
          !empty(response_data.response) && isArray(response_data.response)
            ? response_data.response
            : [];
        const reIndexedSubjects = reIndex(classSubjects, "_id");
        return setReIndexedSubjects(reIndexedSubjects);
      }
    } catch (error) {
      responseDailog("error", "Internal Server", `Something went wrong.`);
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (!isActionLoading) setIsActionLoading(true);
      if (empty(values)) {
        responseDailog(
          "error",
          "Internal Server",
          `There are no values provided`
        );
      }
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const staffId = !empty(user) && !empty(user._id) ? user._id : "";
      const studentId =
        !empty(activeDetails) && !empty(activeDetails._id)
          ? activeDetails._id
          : "";
      const ca1 = !isUndefined(values.ca1) ? values.ca1 : "";
      const ca2 = !isUndefined(values.ca2) ? values.ca2 : "";
      const exam = !isUndefined(values.exam) ? values.exam : "";
      if (ca1 > ca1_max) {
        responseDailog(
          "error",
          "Validation Error",
          `CA 1 score: ${ca1} exceeds the maximum allowed value: ${ca1_max}`
        );
      }
      if (ca2 > ca2_max) {
        responseDailog(
          "error",
          "Validation Error",
          `CA 2 score: ${ca2} exceeds the maximum allowed value: ${ca2_max}`
        );
      }
      if (exam > exam_max) {
        return responseDailog(
          "error",
          "Validation Error",
          `Exam score: ${exam} exceeds the maximum allowed value: ${exam_max}`
        );
      }
      const response = await assessmentApi.recordAssessment(
        schoolId,
        studentId,
        ca1,
        ca2,
        exam,
        subjectId,
        staffId
      );
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Something went wrong!"
        );
      }
      setIsStudentDialog(false);
      await getStudents();
      return responseDailog("success", "Success", "Operation successful");
    } catch (error) {
      return responseDailog(
        "error",
        "Internal Server Error",
        `Something went wrong.`
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  const handleCommentSubmit = async (values) => {
    try {
      if (!isActionLoading) setIsActionLoading(true);
      if (empty(values)) {
      }
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const staffId = !empty(user) && !empty(user._id) ? user._id : "";
      const studentId =
        !empty(activeDetails) && !empty(activeDetails._id)
          ? activeDetails._id
          : "";
      const comment = !empty(values.comment) ? values.comment : "";
      const response = await assessmentApi.addStudentPerformanceComment(
        schoolId,
        studentId,
        comment,
        staffId
      );
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Something went wrong!"
        );
      }

      await getStudents();
      return responseDailog(
        "success",
        "Success",
        "Comment added successfully."
      );
    } catch (error) {
      return responseDailog(
        "error",
        "Internal Server Error",
        `Something went wrong.`
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  const handlePsychomotorSubmit = async (values) => {
    try {
      if (isStepper) {
        setIsStepper(false);
        return null;
      }
      if (!isActionLoading) setIsActionLoading(true);

      const validValues = validPayload(values);
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const staffId = !empty(user) && !empty(user._id) ? user._id : "";
      const studentId =
        !empty(activeDetails) && !empty(activeDetails._id)
          ? activeDetails._id
          : "";
      const response = await assessmentApi.addPsychomotorForStudent(
        schoolId,
        studentId,
        sessionId,
        termId,
        validValues,
        staffId
      );
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Something went wrong!"
        );
      }

      await getStudents();
      return responseDailog(
        "success",
        "Success",
        "Psychomotor updated successfully."
      );
    } catch (error) {
      return responseDailog(
        "error",
        "Internal Server Error",
        `Something went wrong.`
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  const handleAffectiveDomainSubmit = async (values) => {
    try {
      if (isStepper) {
        setIsStepper(false);
        return null;
      }
      if (!isActionLoading) setIsActionLoading(true);

      const validValues = validPayload(values);
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const staffId = !empty(user) && !empty(user._id) ? user._id : "";
      const studentId =
        !empty(activeDetails) && !empty(activeDetails._id)
          ? activeDetails._id
          : "";
      const response = await assessmentApi.addAffectiveDomainForStudent(
        schoolId,
        studentId,
        sessionId,
        termId,
        validValues,
        staffId
      );
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return responseDailog(
          "error",
          "Error Alert",
          !empty(response_data) && !empty(response_data.response)
            ? response_data.response
            : "Something went wrong!"
        );
      }

      await getStudents();
      return responseDailog(
        "success",
        "Success",
        "Psychomotor updated successfully."
      );
    } catch (error) {
      return responseDailog(
        "error",
        "Internal Server Error",
        `Something went wrong.`
      );
    } finally {
      setIsActionLoading(false);
    }
  };

  const openAssessmentModal = (data, openModal = true) => {
    data = !empty(data) && isObject(data) ? data : {};
    assessmentData(data);
    if (openModal) {
      setIsStudentDialog(true);
    }
  };

  const assessmentData = (data = {}) => {
    try {
      const studentAssessmentData =
        !empty(data) && !empty(data.assessment) ? data.assessment : [];
      const studentReport =
        !empty(data) && !empty(data.reports) ? data.reports : [];
      let count = 0;
      let assessmentSum = 0;
      let currentStudentAssessmentData = studentAssessmentData.filter(
        (item) => {
          item._id = count;
          const ca1 = !empty(item.ca1) && parseInt(item.ca1) ? item.ca1 : 0;
          const ca2 = !empty(item.ca2) && parseInt(item.ca2) ? item.ca2 : 0;
          const exam = !empty(item.exam) && parseInt(item.exam) ? item.exam : 0;
          const total = parseInt(ca1) + parseInt(ca2) + parseInt(exam);

          const itemSessionId =
            !empty(item) && !empty(item.sessionId) ? item.sessionId : "";
          const itemTermId =
            !empty(item) && !empty(item.termId) ? item.termId : "";
          const subjectId =
            !empty(item) && !empty(item.subjectId) ? item.subjectId : "";

          if (
            itemSessionId === sessionId &&
            itemTermId === termId &&
            !empty(reIndexedSubjects[subjectId])
          ) {
            assessmentSum += total;
          }
          count++;
          return (
            itemSessionId === sessionId &&
            itemTermId === termId &&
            !empty(reIndexedSubjects[subjectId])
          );
        }
      );

      setSelectedStudentTotal(assessmentSum);

      currentStudentAssessmentData =
        !empty(currentStudentAssessmentData) &&
        isArray(currentStudentAssessmentData)
          ? currentStudentAssessmentData
          : [];
      const reIndexedAssessment = reIndex(
        currentStudentAssessmentData,
        "subjectId"
      );
      const currentSubject =
        !empty(reIndexedAssessment) &&
        isObject(reIndexedAssessment) &&
        !empty(reIndexedAssessment[subjectId])
          ? reIndexedAssessment[subjectId]
          : {};
      const ca1 =
        !empty(currentSubject) && !empty(currentSubject.ca1)
          ? currentSubject.ca1
          : 0;
      const ca2 =
        !empty(currentSubject) && !empty(currentSubject.ca2)
          ? currentSubject.ca2
          : 0;
      const exam =
        !empty(currentSubject) && !empty(currentSubject.exam)
          ? currentSubject.exam
          : 0;
      setInitialValues({
        ca1,
        ca2,
        exam,
      });

      let comment = "";
      let studentPsychomotor = {};
      let affectiveDomain = {};
      for (let i = 0; i < studentReport.length; i++) {
        const reportItem = !empty(studentReport[i]) ? studentReport[i] : {};
        if (!empty(reportItem)) {
          const itemSessionId = !empty(reportItem.sessionId)
            ? reportItem.sessionId
            : "";
          const itemTermId = reportItem.termId ? reportItem.termId : "";
          if (itemSessionId === sessionId && itemTermId === termId) {
            comment = !empty(reportItem.classTeacherComment)
              ? reportItem.classTeacherComment
              : "";

            studentPsychomotor =
              !empty(reportItem.psychomotor) && isObject(reportItem.psychomotor)
                ? reportItem.psychomotor
                : {};
            affectiveDomain =
              !empty(reportItem.affectiveDomain) &&
              isObject(reportItem.affectiveDomain)
                ? reportItem.affectiveDomain
                : {};
            break;
          }
        }
      }

      setStudentReportComment(comment);
      setStudentPsychomotor(studentPsychomotor);
      setStudentAffectiveDomain(affectiveDomain);
      setStudentAssessment(currentStudentAssessmentData);
      setActiveDetails(data);
    } catch (error) {}
  };

  //function to hide school edit modal
  const closeAssessmentDialog = () => {
    setIsStudentDialog(false);
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const onSearchChange = (event) => {
    setSearch(event.target.value);
    setFirst(0);
  };

  const subjectTemplate = (data) => {
    const assessmentSubjectId =
      !empty(data) && !empty(data.subjectId) ? data.subjectId : "";
    const subjectTitle =
      !empty(reIndexedSubjects) &&
      !empty(reIndexedSubjects[assessmentSubjectId]) &&
      !empty(reIndexedSubjects[assessmentSubjectId].title)
        ? reIndexedSubjects[assessmentSubjectId].title
        : "";

    return subjectTitle;
  };

  const ca1Template = (data) => {
    const ca1 = !empty(data) && !empty(data.ca1) ? data.ca1 : 0;
    return ca1;
  };

  const ca2Template = (data) => {
    const ca2 = !empty(data) && !empty(data.ca2) ? data.ca2 : 0;
    return ca2;
  };

  const examTemplate = (data) => {
    const exam = !empty(data) && !empty(data.exam) ? data.exam : 0;
    return exam;
  };

  const totalTemplate = (ca1, ca2, examScore) => {
    const total = parseInt(ca1) + parseInt(ca2) + parseInt(examScore);

    let score_tag = "";
    if (total >= 70) {
      score_tag = "green";
    } else if (total >= 60 && total <= 69.9) {
      score_tag = "#633ccd";
    } else if (total >= 50 && total <= 59.9) {
      score_tag = "orange";
    } else if (total >= 45 && total <= 49.9) {
      score_tag = "gray";
    } else {
      score_tag = "red";
    }

    return <span style={{ color: score_tag }}>{total}</span>;
  };

  const gradeTemplate = (ca1, ca2, exam) => {
    const total = parseInt(ca1) + parseInt(ca2) + parseInt(exam);

    const getGrade = findGrade(remarks, scoreGrades, defaultCategoryId, total);
    const grade =
      !empty(getGrade) && !empty(getGrade.grade) ? getGrade.grade : "";

    return grade;
  };

  const remarkTemplate = (ca1, ca2, exam) => {
    const total = parseInt(ca1) + parseInt(ca2) + parseInt(exam);

    const getGrade = findGrade(remarks, scoreGrades, defaultCategoryId, total);
    const remark =
      !empty(getGrade) && !empty(getGrade.remark) ? getGrade.remark : "";

    return remark;
  };

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="tableCard">
            {/*  header start */}
            <MainHeader
              title="Record Assessments"
              children={
                <>
                  <span style={{ marginRight: 30 }}>
                    Session: <strong>{currentSessionTitle}</strong>
                  </span>
                  <span style={{ marginRight: 30 }}>
                    Term: <strong>{currentTermTitle}</strong>
                  </span>
                  <span style={{ marginRight: 30 }}>
                    Class:{" "}
                    <strong>{!empty(classTitle) ? classTitle : ""}</strong>
                  </span>

                  <span>
                    Subject:{" "}
                    <strong>{!empty(subjectTitle) ? subjectTitle : ""}</strong>
                  </span>
                </>
              }
            />
            {/* end of header */}

            {/* table start */}
            {!isLoading ? (
              <StudentsTable
                students={studentData}
                openAssessmentModal={openAssessmentModal}
                onPageChange={onPageChange}
                rows={isUndefined(rows) || isNaN(rows) ? 50 : rows}
                totalRecords={totalRecords}
                first={isUndefined(first) || isNaN(first) ? 0 : first}
                search={search}
                onSearchChange={onSearchChange}
                assessment={true}
                recordScores={true}
              />
            ) : (
              <TableLoading rows={7} header={false} />
            )}
            {/* table end  */}
          </div>
        </main>
        {isActionLoading && <FullPageLoader visible={isActionLoading} />}
      </AppWrapper>

      <Dialog
        visible={isStudentDialog}
        style={{ width: "70rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={`Record Assessment`}
        modal
        className="p-fluid"
        onHide={closeAssessmentDialog}
      >
        <>
          <p>
            <span style={{ fontSize: 14 }}>
              Session - Term:
              <strong className="block mt-5">
                {" " + currentSessionTitle} - {currentTermTitle}
              </strong>
            </span>
          </p>
          <p className="student-assessment-details">
            <span style={{ marginRight: 40, fontSize: 14 }}>
              Student Name:
              <NavLink
                to={`/student/${
                  !empty(activeDetails) && !empty(activeDetails._id)
                    ? " " + activeDetails._id
                    : ""
                }/profile`}
                style={{ textDecoration: "none", color: colors.primary }}
              >
                <strong className="block mt-5">
                  {!empty(activeDetails) && !empty(activeDetails.fullName)
                    ? " " + activeDetails.fullName
                    : ""}
                </strong>
              </NavLink>
            </span>
            <span style={{ marginRight: 40, fontSize: 14 }}>
              Subject:
              <strong className="block mt-5">
                {!empty(subjectTitle) ? " " + subjectTitle : ""}
              </strong>
            </span>
            <span style={{ fontSize: 14 }}>
              Class:
              <strong className="block mt-5">
                {!empty(classTitle) ? " " + classTitle : ""}
              </strong>
            </span>
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form
              style={{
                width: "100%",
              }}
            >
              <div className="sm-reverse-direction-vertical col-g-8">
                <InputField
                  placeholder="Enter CA1 Score"
                  name="ca1"
                  height={50}
                  type="number"
                  labelTitle="CA1"
                />
                <InputField
                  placeholder="Enter CA2 Score"
                  name="ca2"
                  height={50}
                  type="number"
                  labelTitle="CA2"
                />
                <InputField
                  placeholder="Enter Exam Score"
                  name="exam"
                  height={50}
                  type="number"
                  labelTitle="Exam"
                />
              </div>
              <ButtonIcon
                height={45}
                marginTop={2}
                color="#ffffff"
                backgroundColor="#633ccd"
                width={120}
                borderColor="#633ccd"
                buttonText="Update"
                type="submit"
              />
            </Form>
          </Formik>
          <>
            <div>
              <div style={{ marginTop: 50, marginBottom: 20 }}>
                <strong style={{ fontSize: 16 }}>Recorded Assessments</strong>
              </div>
              {
                <DataTable
                  value={studentAssessment}
                  tableStyle={{ minWidth: "30rem" }}
                  dataKey="_id"
                  stateKey="dt-state-demo-local"
                >
                  <Column
                    field="subjects"
                    header="Subjects"
                    body={subjectTemplate}
                  ></Column>
                  <Column field="ca1" header="CA1" body={ca1Template}></Column>
                  <Column field="ca2" header="CA2" body={ca2Template}></Column>
                  <Column
                    field="exam"
                    header="Exam"
                    body={examTemplate}
                  ></Column>
                  <Column
                    field="total"
                    header="Total"
                    body={(rowData) => {
                      const examScore = examTemplate(rowData);
                      const ca1 = ca1Template(rowData);
                      const ca2 = ca2Template(rowData);
                      return totalTemplate(ca1, ca2, examScore);
                    }}
                  ></Column>
                  <Column
                    field="grade"
                    header="Grade"
                    body={(rowData) => {
                      const examScore = examTemplate(rowData);
                      const ca1 = ca1Template(rowData);
                      const ca2 = ca2Template(rowData);
                      return gradeTemplate(ca1, ca2, examScore);
                    }}
                  ></Column>
                  <Column
                    field="remark"
                    header="Remark"
                    body={(rowData) => {
                      const examScore = examTemplate(rowData);
                      const ca1 = ca1Template(rowData);
                      const ca2 = ca2Template(rowData);
                      return remarkTemplate(ca1, ca2, examScore);
                    }}
                  ></Column>
                </DataTable>
              }
              <div className="mt-20 flex assessment_summary">
                <span className="mr-60">
                  <strong>No. of Subjects: </strong>
                  {studentAssessment.length}
                </span>
                <span className="mr-60">
                  <strong>Total: </strong>
                  {selectedStudentTotal}
                </span>
                <span>
                  <strong>Average: </strong>
                  {typeof selectedStudentTotal === NaN ||
                  typeof studentAssessment.length === NaN ||
                  studentAssessment.length <= 0
                    ? 0
                    : (selectedStudentTotal / studentAssessment.length).toFixed(
                        1
                      )}
                  %
                </span>
              </div>
              <div className="psychomoto_form_box">
                {!empty(affectiveDomainData) && isArray(affectiveDomainData) ? (
                  <div>
                    <div style={{ marginTop: 50, marginBottom: 10 }}>
                      <strong style={{ fontSize: 16 }}>
                        Student's Affective Domain
                      </strong>
                    </div>
                    <div className="affective-domain">
                      <Formik
                        initialValues={{
                          ...affectiveDomainData.reduce((acc, item) => {
                            const title =
                              !empty(item) && !empty(item.title)
                                ? item.title
                                : "";
                            const snakeCaseTitle = convertToSnakeCase(title);

                            // Check if studentAffectiveDomain has a corresponding value
                            acc[snakeCaseTitle] =
                              studentAffectiveDomain[snakeCaseTitle] || "";
                            return acc;
                          }, {}),
                        }}
                        onSubmit={handleAffectiveDomainSubmit}
                      >
                        {({ values, handleChange }) => (
                          <Form
                            style={{
                              maxWidth: "100%",
                            }}
                          >
                            <div className="asseessment_box">
                              <Stepper
                                ref={stepperRef}
                                style={{ flexBasis: "50rem" }}
                                orientation="vertical"
                              >
                                {affectiveDomainData.map((item, index) => {
                                  const title =
                                    !empty(item) && !empty(item.title)
                                      ? capitalize(item.title)
                                      : "";
                                  const fieldName = convertToSnakeCase(title);
                                  return (
                                    <StepperPanel header={title} key={index}>
                                      <div
                                        className="flex flex-column"
                                        key={index}
                                      >
                                        <SelectField
                                          index={index}
                                          placeholder={`Rate student's ${title}`}
                                          name={fieldName}
                                          options={ratingIndicesData}
                                          height={50}
                                          width={200}
                                          display="value"
                                          valueKey="value"
                                          display1="description"
                                          selectedOption={values[fieldName]}
                                          handleChangeFunc={handleChange}
                                        />
                                      </div>
                                      {/* <div className="flex pt-4 justify-content-end">
                                        {index > 0 && (
                                          <Button
                                            label="Back"
                                            severity="secondary"
                                            icon="pi pi-arrow-left"
                                            onClick={() => {
                                              stepperRef.current.prevCallback();
                                              setIsStepper(true);
                                            }}
                                            style={{
                                              width: 120,
                                              marginRight: 13,
                                            }}
                                          />
                                        )}
                                        {index <
                                          affectiveDomainData.length - 1 && (
                                          <Button
                                            label="Next"
                                            icon="pi pi-arrow-right"
                                            iconPos="right"
                                            onClick={() => {
                                              stepperRef.current.nextCallback();
                                              setIsStepper(true);
                                            }}
                                            style={{
                                              width: 120,
                                            }}
                                          />
                                        )}
                                      </div> */}
                                    </StepperPanel>
                                  );
                                })}
                              </Stepper>
                            </div>
                            <ButtonIcon
                              height={45}
                              marginTop={2}
                              color={colors.white}
                              backgroundColor={colors.primary}
                              width={120}
                              borderColor={colors.primary}
                              buttonText="Update"
                              type="submit"
                            />
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                ) : null}
                {!empty(psychomotor) && isArray(psychomotor) ? (
                  <div>
                    <div style={{ marginTop: 50, marginBottom: 10 }}>
                      <strong style={{ fontSize: 18 }}>
                        Student's Psychomotor
                      </strong>
                    </div>
                    <div className="psychomotor">
                      <Formik
                        initialValues={{
                          ...psychomotor.reduce((acc, item) => {
                            const title =
                              !empty(item) && !empty(item.title)
                                ? item.title
                                : "";
                            const snakeCaseTitle = convertToSnakeCase(title);

                            // Check if userPsychomotor has a corresponding value
                            acc[snakeCaseTitle] =
                              studentPsychomotor[snakeCaseTitle] || "";
                            return acc;
                          }, {}),
                        }}
                        onSubmit={handlePsychomotorSubmit}
                      >
                        {({ values, handleChange }) => (
                          <Form
                            style={{
                              maxWidth: "100%",
                            }}
                          >
                            <div className="asseessment_box">
                              <Stepper ref={stepperRef} orientation="vertical">
                                {psychomotor.map((item, index) => {
                                  const title =
                                    !empty(item) && !empty(item.title)
                                      ? capitalize(item.title)
                                      : "";
                                  const fieldName = convertToSnakeCase(title);
                                  return (
                                    <StepperPanel header={title} key={index}>
                                      <div className="flex">
                                        <SelectField
                                          key={index}
                                          index={index}
                                          labelTitle={title}
                                          placeholder={`Rate student's ${title}`}
                                          name={fieldName}
                                          options={ratingIndicesData}
                                          height={50}
                                          width={200}
                                          display="value"
                                          valueKey="value"
                                          display1="description"
                                          selectedOption={values[fieldName]}
                                          handleChangeFunc={handleChange}
                                        />
                                      </div>
                                      {/* <div className="flex pt-4 justify-content-end">
                                        {index > 0 && (
                                          <Button
                                            label="Back"
                                            severity="secondary"
                                            icon="pi pi-arrow-left"
                                            onClick={() => {
                                              stepperRef.current.prevCallback();
                                              setIsStepper(true);
                                            }}
                                            style={{
                                              width: 120,
                                              marginRight: 13,
                                            }}
                                          />
                                        )}
                                        {index <
                                          affectiveDomainData.length - 1 && (
                                          <Button
                                            label="Next"
                                            icon="pi pi-arrow-right"
                                            iconPos="right"
                                            onClick={() => {
                                              stepperRef.current.nextCallback();
                                              setIsStepper(true);
                                            }}
                                            style={{
                                              width: 120,
                                            }}
                                          />
                                        )}
                                      </div> */}
                                    </StepperPanel>
                                  );
                                })}
                              </Stepper>
                            </div>
                            <ButtonIcon
                              height={45}
                              marginTop={2}
                              color={colors.white}
                              backgroundColor={colors.primary}
                              width={120}
                              borderColor={colors.primary}
                              buttonText="Update"
                              type="submit"
                            />
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                ) : null}
              </div>
              <div style={{ marginTop: 50, marginBottom: 10 }}>
                <strong style={{ fontSize: 18 }}>
                  Class Teacher's Comment
                </strong>
              </div>
              <Formik
                initialValues={{ comment: studentReportComment }}
                validationSchema={validationCommentSchema}
                onSubmit={handleCommentSubmit}
              >
                <Form
                  style={{
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: 8,
                    }}
                  >
                    <InputField
                      placeholder="Enter comment for student performance"
                      name="comment"
                      height={150}
                      labelTitle="Comment"
                      as="textarea"
                      rows={8}
                      cols={10}
                    />
                  </div>
                  <ButtonIcon
                    height={45}
                    marginTop={2}
                    color={colors.white}
                    backgroundColor={colors.primary}
                    width={120}
                    borderColor={colors.primary}
                    buttonText="Update"
                    type="submit"
                  />
                </Form>
              </Formik>
            </div>
          </>
        </>
      </Dialog>
      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default RecordAssessment;
