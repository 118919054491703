import client from "./Client";

const getSubjects = (schoolId) =>
  client.get(`/staff/school/${schoolId}/subjects`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

const getSingleSubject = (subject_id, school_id) =>
  client.get(`/staff/school/${school_id}/subject/${subject_id}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

const positionSubject = (
  school_id,
  class_id,
  session_id,
  term_id,
  subject_id,
  user_id
) =>
  client.post("/staff/student/subject/position", {
    school_id,
    class_id,
    session_id,
    term_id,
    subject_id,
    user_id,
  });

export default {
  getSubjects,
  getSingleSubject,
  positionSubject,
};
