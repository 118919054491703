import client from "./Client";

const getStudents = (schoolId, page = 0, rows = 50, search = "", type = "") => {
  const params = {};
  if (page) {
    params.page = page;
  }
  if (rows) {
    params.rows = rows;
  }
  if (search) {
    params.search = search;
  }
  if (type) {
    params.type = type;
  }

  return client.get(`/staff/school/${schoolId}/students`, {
    params,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const getStudentsInClass = (school_id, page, rows, search, class_id, type) => {
  const params = {};
  if (page >= 0) {
    params.page = page;
  }
  if (rows >= 0) {
    params.rows = rows;
  }
  if (search) {
    params.search = search;
  }
  if (type) {
    params.type = type;
  }
  return client.get(`/staff/school/${school_id}/class/${class_id}/students`, {
    params,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const getSingleStudent = (studentId, schoolId) =>
  client.get(`/staff/school/${schoolId}/student/${studentId}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

const getStudentProfile = (studentId, schoolId) =>
  client.get(`/staff/school/${schoolId}/student/${studentId}/profile`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

const getStudentPercentage = (studentId, schoolId) =>
  client.get(
    `/staff/school/${schoolId}/stat/student/${studentId}/assessment/percentage`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export default {
  getStudents,
  getSingleStudent,
  getStudentsInClass,
  getStudentProfile,
  getStudentPercentage,
};
