import "./Sidebar.css";
import {
  FaPowerOff,
  FaSchool,
  FaUserGraduate,
  FaUserTie,
  FaTh,
  FaBook,
  FaLayerGroup,
  FaListAlt,
  FaCheckSquare,
  FaBookOpen,
  FaCog,
  FaSwatchbook,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useContext } from "react";

// hooks
import { AuthContext } from "../../screens/Root/ProtectedRoute";
import { empty, isString } from "../../Utilities/utils";
// images
import appLogo from "../../assets/logo/blue-logo.png";

const Sidebar = ({ sidebarOpen, toggleSidebar }) => {
  const { user } = useContext(AuthContext);
  const userAvatar = !empty(user) && !empty(user.avatar) ? user.avatar : "";
  const fullName =
    !empty(user) && !empty(user.fullName) && isString(user.fullName)
      ? user.fullName.toUpperCase()
      : "";
  const title =
    !empty(user) && !empty(user.title) && isString(user.title)
      ? user.title.toUpperCase()
      : "";
  const schoolAvatar =
    !empty(user.schoolData) && !empty(user.schoolData.schoolAvatar)
      ? user.schoolData.schoolAvatar
      : "";
  const sessionTitle =
    !empty(user.schoolData) && !empty(user.schoolData.currentSessionTitle)
      ? user.schoolData.currentSessionTitle
      : "";
  const termTitle =
    !empty(user.schoolData) && !empty(user.schoolData.currentTermTitle)
      ? user.schoolData.currentTermTitle
      : "";
  const reverseSidebar = () => {
    toggleSidebar();
  };

  return (
    <div className={sidebarOpen ? "sidebar_responsive" : ""} id="sidebar">
      <div className="sidebar_title">
        <div className="flex flex-center direction-column align-center sidebar-image-container">
          <img
            className="sidebar-header-image"
            src={
              !empty(userAvatar)
                ? userAvatar
                : !empty(schoolAvatar)
                ? schoolAvatar
                : appLogo
            }
            alt="logo"
            loading="lazy"
          />
          <p className="text-black text-center">
            <span>
              {title} {fullName} <br />
              (Staff)
              <br />
            </span>
            <br />
            <span>
              {sessionTitle} | {termTitle}
            </span>
          </p>
        </div>
      </div>

      <div className="sidebar_menu">
        {/* sidebar link */}
        <div className="separator">Home</div>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/dashboard"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaTh className="sidebar_link_icon" />
              <span className="sidebar_link_text">Dashboard</span>
            </div>
          )}
        </NavLink>

        <div className="separator">Users</div>
        {/* sidebar link */}
        <NavLink
          onClick={() => reverseSidebar()}
          to="/students"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaUserGraduate className="sidebar_link_icon" />
              <div className="sidebar_link_text">Students</div>
            </div>
          )}
        </NavLink>

        {/* sidebar link */}
        <NavLink
          onClick={() => reverseSidebar()}
          to="/staff"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaUserTie className="sidebar_link_icon" />
              <div className="sidebar_link_text">Staff</div>
            </div>
          )}
        </NavLink>

        {/* sidebar link */}
        {/* <NavLink
          onClick={() => reverseSidebar()}
          to="/parent"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaUsers className="sidebar_link_icon" />
              <div className="sidebar_link_text">Parent</div>
            </div>
          )}
        </NavLink> */}

        <div className="separator">Academics</div>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/classes"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaSchool className="sidebar_link_icon" />
              <div className="sidebar_link_text">Classes</div>
            </div>
          )}
        </NavLink>

        <NavLink
          onClick={() => reverseSidebar()}
          to="/subjects"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaBookOpen className="sidebar_link_icon" />
              <div className="sidebar_link_text">Subjects</div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/sessions"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaLayerGroup className="sidebar_link_icon" />
              <div className="sidebar_link_text">Sessions</div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/terms"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaListAlt className="sidebar_link_icon" />
              <div className="sidebar_link_text">Terms</div>
            </div>
          )}
        </NavLink>

        <div className="separator">Assessment</div>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/assessment/set"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaBook className="sidebar_link_icon" />
              <div className="sidebar_link_text">Assessment</div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/assessment/position"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaSwatchbook className="sidebar_link_icon" />
              <div className="sidebar_link_text">Position</div>
            </div>
          )}
        </NavLink>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/assessment/result-checker"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaCheckSquare className="sidebar_link_icon" />
              <div className="sidebar_link_text">Result Checker</div>
            </div>
          )}
        </NavLink>
        <div className="separator">Settings</div>
        <NavLink
          onClick={() => reverseSidebar()}
          to="/settings"
          style={{ textDecoration: "none" }}
        >
          {({ isActive }) => (
            <div
              className={
                isActive ? "sidebar_link active_menu_link" : "sidebar_link"
              }
            >
              <FaCog className="sidebar_link_icon" />
              <div className="sidebar_link_text">Settings</div>
            </div>
          )}
        </NavLink>

        {/* sidebar link */}
        <div className="sidebar_logout mb-20">
          <FaPowerOff className="sidebar_logout_icon" />
          <NavLink to="/logout" className="sidebar_logout_text">
            Logout
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
