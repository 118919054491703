import client from "./Client";

const toggleTermVisibility = (schoolId, termId, active) =>
  client.post(
    "/term/visibility/toggle",
    {
      school_id: schoolId,
      term_id: termId,
      active,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

const getTerms = (schoolId) =>
  client.get(`/staff/school/${schoolId}/terms`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

const getSingleTerm = (schoolId, termId) =>
  client.get(`/staff/school/${schoolId}/term/${termId}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

export default {
  getTerms,
  getSingleTerm,
  toggleTermVisibility,
};
