import { useContext, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  empty,
  isArray,
  isObject,
  isString,
  isUndefined,
  prepareResponseData,
  reIndex,
  toNormalCase,
} from "../../Utilities/utils";
import { AuthContext } from "../Root/ProtectedRoute";
import { toUpper } from "lodash";

// css
import "../students/Students.css";
import "./Assessment.css";

// api
import studentApi from "../../api/Student";
import classApi from "../../api/Classes";
import termApi from "../../api/Terms";
import assessmentApi from "../../api/Assessment";
import sessionApi from "../../api/Session";

//components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import AppWrapper from "../../components/appWrapper/AppWrapper";
import { Toast } from "primereact/toast";
import FullPageLoader from "../../components/loader/FullPageLoader";
import { FaExclamationTriangle } from "react-icons/fa";
import colors from "../../config/colors";
import Barchart from "../../components/chart/barchart/Barchart";
import ButtonIcon from "../../components/buttons/buttonIcon/ButtonIcon";

const DisplayReportSheet = ({ ...props }) => {
  const printRef = useRef();
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  //ref
  const toastTR = useRef(null);
  // states
  const [studentData, setStudentData] = useState([]);
  const [classData, setClassData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [studentAssessment, setStudentAssessment] = useState([]);
  const [reIndexedSubjects, setReIndexedSubjects] = useState({});
  const [activeDetails, setActiveDetails] = useState({});
  const [termData, setTermData] = useState({});
  const [sessionData, setSessionData] = useState({});
  const [studentReport, setStudentReport] = useState({});
  const [hasPaid, setHasPaid] = useState(false);
  const [classTeacherName, setClassTeacherName] = useState("");
  const [ratingIndices, setRatingIndices] = useState([]);
  const [chartData, setChartData] = useState({
    labels: studentAssessment.map((data) => {
      const subjectCode =
        !empty(data) && !empty(data.subjectCode) ? data.subjectCode : "";
      return subjectCode;
    }),
    datasets: [
      {
        label: "Student Performance",
        data: studentAssessment.map((data) => {
          const sum = !empty(data) && !empty(data.total) ? data.total : 0;
          return sum;
        }),
        backgroundColor: colors.charcoal,
        borderRadius: 12,
      },
      {
        label: "Class Average",
        data: studentAssessment.map((data) => {
          const sum = !empty(data) && !empty(data.total) ? data.total : 0;
          return sum;
        }),
        backgroundColor: colors.charcoal,
        borderRadius: 12,
      },
    ],
  });
  const { studentId, termId, sessionId, classId } = useParams();
  if (!studentId || !termId || !sessionId || !classId) {
    navigate("/404");
  }

  const schoolData =
    !empty(user) && !empty(user.schoolData) ? user.schoolData : {};
  const schoolName = !empty(schoolData.schoolName) ? schoolData.schoolName : "";
  const schoolAvatar = !empty(schoolData.schoolAvatar)
    ? schoolData.schoolAvatar
    : "";
  const email = !empty(schoolData.schoolEmail) ? schoolData.schoolEmail : "";
  const address = !empty(schoolData.schoolAddress)
    ? schoolData.schoolAddress
    : "";
  const phoneNumber1 = !empty(schoolData.schoolPhone1)
    ? schoolData.schoolPhone1
    : "";
  const phoneNumber2 = !empty(schoolData.schoolPhone2)
    ? schoolData.schoolPhone2
    : "";
  const ownerPhone = !empty(schoolData.schoolOwnerPhone)
    ? schoolData.schoolOwnerPhone
    : "";
  const closingDate = !empty(schoolData.closingDate)
    ? schoolData.closingDate
    : "";
  const openingDate = !empty(schoolData.openingDate)
    ? schoolData.openingDate
    : "";

  //alert functions
  const responseDailog = (severity = null, summary = null, detail = null) => {
    toastTR?.current?.show({
      severity,
      summary,
      detail,
      life: 8000,
    });
  };

  useEffect(() => {
    // fetch student
    try {
      if (!empty(studentId) && !empty(sessionId) && !empty(termId)) {
        getStudent();
      } else {
        navigate("/404");
      }
    } catch (error) {
      responseDailog(
        "error",
        "Something went wrong",
        "Failed to load student. Please try again later."
      );
    }
  }, []);

  useEffect(() => {
    const loadData = async () => {
      await getTermDetails();
      await getSessionDetails();
      await getSubjectsInClass();
      await getClassDetails();
      await getRatingIndices();
    };
    loadData();
  }, [hasPaid]);

  useEffect(() => {
    assessmentData();
  }, [studentData, reIndexedSubjects]);

  // function to get all student
  const getStudent = async (returnValue = false) => {
    try {
      if (!isLoading) setIsLoading(true);
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      let response;
      response = await studentApi.getSingleStudent(studentId, schoolId);
      const response_data = prepareResponseData(response);
      const returnData =
        !empty(response_data.response) && isObject(response_data.response)
          ? response_data.response
          : {};
      if (empty(returnData)) {
        return setStudentData({});
      } else {
        if (returnValue) {
          return returnData;
        } else {
          const hasPaid =
            !empty(returnData) &&
            !empty(returnData.paid) &&
            returnData.paid === "Yes"
              ? true
              : false;
          setHasPaid(hasPaid);
          setStudentData(returnData);
        }
      }
    } catch (error) {
      responseDailog(
        "error",
        "Error Alert",
        `Something went wrong fetching student students.`
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getClassDetails = async () => {
    try {
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await classApi.getSingleClass(classId, schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setClassData({});
      } else {
        const data =
          !empty(response_data) &&
          !empty(response_data.response) &&
          isObject(response_data.response)
            ? response_data.response
            : {};
        const classTeacherName =
          !empty(data) && !empty(data.classTeacher) ? data.classTeacher : "";
        setClassData(data);
        return setClassTeacherName(classTeacherName);
      }
    } catch (error) {
      return responseDailog(
        "error",
        "Error Alert",
        `Something went wrong fetching class details.`
      );
    }
  };

  const getTermDetails = async () => {
    try {
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await termApi.getSingleTerm(schoolId, termId);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return setTermData({});
      } else {
        return setTermData(
          !empty(response_data.response) && isObject(response_data.response)
            ? response_data.response
            : {}
        );
      }
    } catch (error) {
      responseDailog(
        "error",
        "Error Alert",
        `Something went wrong fetching terms.`
      );
    }
  };

  const getRatingIndices = async () => {
    try {
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await assessmentApi.getRatings(schoolId);
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return setRatingIndices([]);
      } else {
        return setRatingIndices(
          !empty(response_data.response) && isArray(response_data.response)
            ? response_data.response
            : []
        );
      }
    } catch (error) {
      responseDailog(
        "error",
        "Error Alert",
        `Something went wrong fetching rating indices.`
      );
    }
  };

  const getSubjectsInClass = async () => {
    try {
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await classApi.getSubjectsInClass(
        classId,
        schoolId,
        1000
      );
      const response_data = prepareResponseData(response);
      if (
        empty(response_data) ||
        empty(response_data.success) ||
        !response_data.success
      ) {
        return setReIndexedSubjects({});
      } else {
        const classSubjects =
          !empty(response_data.response) && isArray(response_data.response)
            ? response_data.response
            : [];
        const reIndexedSubjects = reIndex(classSubjects, "_id");
        return setReIndexedSubjects(reIndexedSubjects);
      }
    } catch (error) {
      return responseDailog(
        "error",
        "Error Alert",
        `Something went wrong fetching subjects in class.`
      );
    }
  };

  const getSessionDetails = async () => {
    try {
      const schoolId =
        !empty(user) && !empty(user.schoolId) ? user.schoolId : "";
      const response = await sessionApi.getSession(sessionId, schoolId);
      const response_data = prepareResponseData(response);
      if (empty(response_data) || !response_data.success) {
        return setSessionData({});
      } else {
        return setSessionData(
          !empty(response_data.response) && isObject(response_data.response)
            ? response_data.response
            : {}
        );
      }
    } catch (error) {
      return responseDailog(
        "error",
        "Error Alert",
        `Something went wrong fetching session details.`
      );
    }
  };

  const assessmentData = () => {
    try {
      const studentAssessmentData =
        !empty(studentData) && !empty(studentData.assessment)
          ? studentData.assessment
          : [];
      const studentReport =
        !empty(studentData) && !empty(studentData.reports)
          ? studentData.reports
          : [];
      const classReports =
        !empty(classData) && !empty(classData.reports) ? classData.reports : [];
      let classReport = {};
      for (let i = 0; i < classReports.length; i++) {
        const studentData = !empty(classReports[i]) ? classReports[i] : {};
        const reportSessionId = !empty(studentData.sessionId)
          ? studentData.sessionId
          : "";
        const reportTermId = !empty(studentData.termId)
          ? studentData.termId
          : "";
        if (reportSessionId === sessionId && reportTermId === termId) {
          classReport = studentData;
          break;
        }
      }
      const selectedSubjectIds =
        !empty(classReport) && !empty(classReport.subjectsToAssess)
          ? classReport.subjectsToAssess
          : [];
      const currentStudentAssessmentData = studentAssessmentData.filter(
        (item) => {
          const itemSessionId =
            !empty(item) && !empty(item.sessionId) ? item.sessionId : "";
          const itemTermId =
            !empty(item) && !empty(item.termId) ? item.termId : "";
          const itemSubjectId =
            !empty(item) && !empty(item.subjectId) ? item.subjectId : "";
          if (
            itemSessionId === sessionId &&
            itemTermId === termId &&
            selectedSubjectIds.includes(itemSubjectId)
          ) {
            const subjectId =
              !empty(item) && !empty(item.subjectId) ? item.subjectId : "";
            item.subjectCode =
              !empty(reIndexedSubjects) &&
              !empty(reIndexedSubjects[subjectId]) &&
              !empty(reIndexedSubjects[subjectId].code)
                ? reIndexedSubjects[subjectId].code
                : "";
            return (item.subjectTitle =
              !empty(reIndexedSubjects) &&
              !empty(reIndexedSubjects[subjectId]) &&
              !empty(reIndexedSubjects[subjectId].title)
                ? reIndexedSubjects[subjectId].title
                : "");
          }
        }
      );

      let currentReport = {};
      for (let i = 0; i < studentReport.length; i++) {
        const itemSessionId =
          !empty(studentReport[i]) && !empty(studentReport[i].sessionId)
            ? studentReport[i].sessionId
            : "";
        const itemTermId =
          !empty(studentReport[i]) && studentReport[i].termId
            ? studentReport[i].termId
            : "";
        if (itemSessionId === sessionId && itemTermId === termId) {
          currentReport = !empty(studentReport[i]) ? studentReport[i] : {};

          studentData.psychomotor =
            !empty(studentReport[i]) && !empty(studentReport[i].psychomotor)
              ? studentReport[i].psychomotor
              : {};
          studentData.affectiveDomain =
            !empty(studentReport[i]) && !empty(studentReport[i].affectiveDomain)
              ? studentReport[i].affectiveDomain
              : {};
          break;
        }
      }

      setStudentReport(currentReport);
      setStudentAssessment(
        isArray(currentStudentAssessmentData)
          ? currentStudentAssessmentData
          : []
      );
      setActiveDetails(studentData);
      // set chart data
      setChartData({
        labels: currentStudentAssessmentData.map((data) => {
          const subjectCode =
            !empty(data) && !empty(data.subjectCode) ? data.subjectCode : "";
          return subjectCode;
        }),
        datasets: [
          {
            label: "Subject Performance",
            data: currentStudentAssessmentData.map((data) => {
              const sum = !empty(data) && !empty(data.total) ? data.total : 0;
              return sum;
            }),
            backgroundColor: colors.primary,
            borderRadius: 12,
          },
          {
            label: `Class Average`,
            data: currentStudentAssessmentData.map((data) => {
              const average =
                !empty(data) && !empty(data.classAverage)
                  ? data.classAverage
                  : 0;
              return average;
            }),
            backgroundColor: colors.orange,
            borderRadius: 12,
          },
        ],
      });
    } catch (error) {}
  };

  const printReport = () => {
    window.print();
  };

  return (
    <>
      <AppWrapper {...props}>
        <main>
          <div className="tableCard">
            {/*  header start */}
            <MainHeader title="Result Sheet Display" />
            {/* end of header */}
            <div className="display-report">
              <div className="report-sheet-display">
                {hasPaid ? (
                  <div className="report-body" ref={printRef}>
                    {/* report header */}
                    <div className="report-header">
                      <div style={{ width: 80, height: 80 }}>
                        <img
                          src={!empty(schoolAvatar) ? schoolAvatar : ""}
                          alt="non"
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                      <div>
                        <p className="report-title">
                          {!empty(schoolName) ? schoolName : "NA"}
                        </p>
                        <p className="report-subtitle">
                          {!empty(address) ? address : "NA"}
                        </p>
                        <p className="report-subtitle fs-14">
                          {`TEL.: ${phoneNumber1}${
                            !empty(phoneNumber2) ? ", " + phoneNumber2 : ""
                          }${!empty(ownerPhone) ? ", " + ownerPhone : ""}`}
                        </p>
                        <p className="report-subtitle fs-14 mb-10">
                          {`Email: ${email}`}
                        </p>
                      </div>
                      <div style={{ width: 80, height: 80 }}>
                        {!empty(activeDetails) &&
                        !empty(activeDetails.avatar) ? (
                          <img
                            src={activeDetails.avatar}
                            alt="student"
                            style={{ width: "100%", height: "100%" }}
                          />
                        ) : (
                          <img
                            src={!empty(schoolAvatar) ? schoolAvatar : ""}
                            alt="non"
                            style={{ width: "100%", height: "100%" }}
                          />
                        )}
                      </div>
                    </div>
                    {/* end of report header */}

                    {/* report top section */}
                    <div className="report-details-header">
                      <p className="report-term-title">
                        {!empty(sessionData) && !empty(sessionData.title)
                          ? sessionData.title
                          : ""}{" "}
                        {!empty(termData) &&
                        !empty(termData.title) &&
                        isString(termData.title)
                          ? termData.title.toUpperCase()
                          : ""}{" "}
                        TERM STUDENT'S PERFORMANCE SHEET
                      </p>
                      <div className="report-detail-group">
                        <p className="report-detail-group-item">
                          <span className="report-detail-group-title">
                            NAME:
                          </span>
                          <span className="report-detail-group-value">
                            <NavLink
                              to={`/student/${studentId}/profile`}
                              style={{
                                textDecoration: "none",
                                color: "#000000",
                              }}
                            >
                              {!empty(activeDetails) &&
                              !empty(activeDetails.fullName)
                                ? toUpper(activeDetails.fullName)
                                : ""}
                            </NavLink>
                          </span>
                        </p>
                        <p className="report-detail-group-item">
                          <span className="report-detail-group-title">
                            GENDER:
                          </span>
                          <span className="report-detail-group-value">
                            {!empty(activeDetails) &&
                            !empty(activeDetails.gender)
                              ? toUpper(activeDetails.gender)
                              : "N/A"}
                          </span>
                        </p>
                        <p className="report-detail-group-item">
                          <span className="report-detail-group-title">
                            CLASS:
                          </span>
                          <span className="report-detail-group-value">
                            {!empty(classData) && !empty(classData.title)
                              ? toUpper(classData.title)
                              : ""}
                          </span>
                        </p>
                      </div>
                      <div className="report-detail-group">
                        <p className="report-detail-group-item">
                          <span className="report-detail-group-title">
                            ADMISSION NO.:
                          </span>
                          <span className="report-detail-group-value">
                            {!empty(activeDetails) &&
                            !empty(activeDetails.regNo)
                              ? toUpper(activeDetails.regNo)
                              : ""}
                          </span>
                        </p>
                        <p className="report-detail-group-item">
                          <span className="report-detail-group-title">
                            ROLL NO.:
                          </span>
                          <span className="report-detail-group-value">
                            {!empty(activeDetails) &&
                            !empty(activeDetails.rollNumber)
                              ? toUpper(activeDetails.rollNumber)
                              : "N/A"}
                          </span>
                        </p>
                      </div>

                      <div className="report-rating-box">
                        <table className="grade-rating-table">
                          <thead>
                            <tr>
                              <th>GRADE</th>
                              <th>A</th>
                              <th>B</th>
                              <th>C</th>
                              <th>D</th>
                              <th>E</th>
                              <th>F</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th>NO</th>
                              <td>
                                {!empty(studentReport) &&
                                !empty(studentReport.noOfAs)
                                  ? toUpper(studentReport.noOfAs)
                                  : 0}
                              </td>
                              <td>
                                {!empty(studentReport) &&
                                !empty(studentReport.noOfBs)
                                  ? toUpper(studentReport.noOfBs)
                                  : 0}
                              </td>
                              <td>
                                {!empty(studentReport) &&
                                !empty(studentReport.noOfCs)
                                  ? toUpper(studentReport.noOfCs)
                                  : 0}
                              </td>
                              <td>
                                {!empty(studentReport) &&
                                !empty(studentReport.noOfDs)
                                  ? toUpper(studentReport.noOfDs)
                                  : 0}
                              </td>
                              <td>
                                {!empty(studentReport) &&
                                !empty(studentReport.noOfEs)
                                  ? toUpper(studentReport.noOfEs)
                                  : 0}
                              </td>
                              <td>
                                {!empty(studentReport) &&
                                !empty(studentReport.noOfFs)
                                  ? toUpper(studentReport.noOfFs)
                                  : 0}
                              </td>
                            </tr>
                            <tr>
                              <th colSpan={6}>NUMBER OF SUBJECTS OFFERED</th>
                              <td>
                                {!empty(studentReport) &&
                                !empty(studentReport.noOfSubjectsOffered)
                                  ? studentReport.noOfSubjectsOffered
                                  : "NA"}
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <table className="score-rating-table">
                          <tbody>
                            <tr>
                              <th>AVERAGE</th>
                              <td>
                                {!empty(studentReport) &&
                                !empty(studentReport.average)
                                  ? studentReport.average
                                  : "NA"}
                              </td>
                            </tr>
                            <tr>
                              <th>GRADE</th>
                              <td>
                                {!empty(studentReport) &&
                                !empty(studentReport.grade) &&
                                !empty(studentReport.remark)
                                  ? studentReport.grade +
                                    " - " +
                                    toUpper(studentReport.remark)
                                  : "NA"}
                              </td>
                            </tr>
                            <tr>
                              <th>POSITION</th>
                              <td>
                                {!empty(studentReport) &&
                                !empty(studentReport.position)
                                  ? toUpper(studentReport.position)
                                  : "NA"}
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <table className="score-rating-table">
                          <tbody>
                            <tr>
                              <td>Number of Students in class</td>
                              <td>
                                {!empty(studentData) &&
                                !empty(studentData.numberOfStudents)
                                  ? studentData.numberOfStudents
                                  : "NA"}
                              </td>
                            </tr>
                            <tr>
                              <td>Marks Obtained</td>
                              <td>
                                {!empty(studentReport) &&
                                !empty(studentReport.cumulativeScore)
                                  ? studentReport.cumulativeScore
                                  : "NA"}
                              </td>
                            </tr>
                            <tr>
                              <td>Marks Obtainable</td>
                              <td>
                                {!empty(studentReport) &&
                                !empty(studentReport.noOfSubjectsOffered)
                                  ? studentReport.noOfSubjectsOffered * 100
                                  : "NA"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* end of report top secit */}

                    <div className="report-containter">
                      <table className="report-sheet-area">
                        <thead>
                          <tr>
                            <th rowSpan={3}>COGNITIVE DOMAIN</th>
                            <th rowSpan={4}>
                              <span className="report-title-rotate">CODE</span>
                            </th>
                            <th rowSpan={2} colSpan={2}>
                              CA
                            </th>
                            <th rowSpan={3}>
                              <span className="report-title-rotate">EXAM</span>
                            </th>
                            <th rowSpan={3}>
                              <span className="report-title-rotate">TOTAL</span>
                            </th>
                            <th rowSpan={4}>
                              <span className="report-title-rotate">GRADE</span>
                            </th>
                            <th rowSpan={4}>
                              <span className="report-title-rotate">
                                POSITION
                              </span>
                            </th>
                            <th rowSpan={4}>
                              <span className="report-title-rotate">
                                REMARKS
                              </span>
                            </th>
                            <th rowSpan={4}>
                              <span className="report-title-rotate">
                                CLASS MIN.
                              </span>
                            </th>
                            <th rowSpan={4}>
                              <span className="report-title-rotate">
                                CLASS MAX.
                              </span>
                            </th>
                            <th rowSpan={4}>
                              <span className="report-title-rotate">
                                CLASS AVG.
                              </span>
                            </th>
                          </tr>
                          <tr></tr>
                          <tr>
                            <th>1st</th>
                            <th>2nd</th>
                          </tr>
                          <tr>
                            <th>SUBJECTS</th>
                            <th>20</th>
                            <th>20</th>
                            <th>60</th>
                            <th>100</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!empty(studentAssessment) ? (
                            studentAssessment.map((data) => {
                              return (
                                <tr
                                  key={
                                    !empty(data) && !empty(data.subjectId)
                                      ? data.subjectId
                                      : ""
                                  }
                                >
                                  <td>
                                    {!empty(data) && !empty(data.subjectTitle)
                                      ? toUpper(data.subjectTitle)
                                      : ""}
                                  </td>
                                  <td>
                                    {!empty(data) && !empty(data.subjectCode)
                                      ? data.subjectCode
                                      : 0}
                                  </td>
                                  <td>
                                    {!empty(data) && !empty(data.ca1)
                                      ? data.ca1
                                      : 0}
                                  </td>
                                  <td>
                                    {!empty(data) && !empty(data.ca2)
                                      ? data.ca2
                                      : 0}
                                  </td>
                                  <td>
                                    {!empty(data) && !empty(data.exam)
                                      ? data.exam
                                      : 0}
                                  </td>
                                  <td>
                                    {!empty(data) && !empty(data.total)
                                      ? data.total
                                      : 0}
                                  </td>
                                  <td>
                                    {!empty(data) && !empty(data.grade)
                                      ? data.grade
                                      : ""}
                                  </td>
                                  <td>
                                    {!empty(data.position)
                                      ? data.position
                                      : "NA"}
                                  </td>
                                  <td>
                                    {!empty(data) && !empty(data.remark)
                                      ? toUpper(data.remark)
                                      : ""}
                                  </td>
                                  <td>
                                    {!empty(data) &&
                                    !isUndefined(data.classMinValue)
                                      ? data.classMinValue
                                      : ""}
                                  </td>
                                  <td>
                                    {!empty(data) &&
                                    !isUndefined(data.classMaxValue)
                                      ? data.classMaxValue
                                      : ""}
                                  </td>
                                  <td>
                                    {!empty(data) &&
                                    !isUndefined(data.classAverage)
                                      ? data.classAverage
                                      : ""}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr></tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="psycho_area">
                      {!empty(activeDetails) &&
                      !empty(activeDetails.psychomotor) ? (
                        <div className="pyschomotor_box">
                          <table className="psycho-table">
                            <thead>
                              <tr>
                                <th>Psychomotor</th>
                                <th>Rating</th>
                              </tr>
                            </thead>
                            <tbody>
                              {isObject(activeDetails.psychomotor) &&
                                Object.entries(activeDetails.psychomotor).map(
                                  ([key, value]) => (
                                    <tr key={key}>
                                      <td>
                                        {!empty(key) ? toNormalCase(key) : ""}
                                      </td>
                                      <td>{!empty(value) ? value : ""}</td>
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </table>
                        </div>
                      ) : null}
                      {!empty(activeDetails) &&
                      !empty(activeDetails.affectiveDomain) ? (
                        <div className="affective_domain_box">
                          <table className="psycho-table">
                            <thead>
                              <tr>
                                <th>Affective Domain</th>
                                <th>Rating</th>
                              </tr>
                            </thead>
                            <tbody>
                              {isObject(activeDetails.affectiveDomain) &&
                                Object.entries(
                                  activeDetails.affectiveDomain
                                ).map(([key, value]) => (
                                  <tr key={key}>
                                    <td>
                                      {!empty(key) ? toNormalCase(key) : ""}
                                    </td>
                                    <td>{!empty(value) ? value : ""}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      ) : null}
                      {!empty(activeDetails) &&
                      (!empty(activeDetails.affectiveDomain) ||
                        !empty(activeDetails.psychomotor)) &&
                      !empty(ratingIndices) &&
                      isArray(ratingIndices) ? (
                        <div className="mt-10 rating_indicators">
                          <strong>Rating Indicators</strong>
                          <ul style={{ listStyle: "none" }}>
                            {ratingIndices.map((item, index) => {
                              const value =
                                !empty(item) && !empty(item.value)
                                  ? item.value
                                  : "";
                              const description =
                                !empty(item) && !empty(item.description)
                                  ? item.description
                                  : "";
                              return (
                                <li className="mt-5" key={index}>
                                  <strong>{value}</strong> - {description}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      ) : null}
                      {!empty(activeDetails) &&
                      (!empty(activeDetails.affectiveDomain) ||
                        !empty(activeDetails.psychomotor)) &&
                      !empty(ratingIndices) &&
                      isArray(ratingIndices) ? (
                        <div
                          style={{
                            marginTop: 10,
                            width: 400,
                            height: 150,
                            display: "flex",
                            justifyContent: "center",
                            marginBottom: 10,
                          }}
                        >
                          <Barchart data={chartData} />
                        </div>
                      ) : (
                        <div
                          style={{
                            marginTop: 10,
                            width: 500,
                            height: 220,
                            display: "flex",
                            justifyContent: "center",
                            marginBottom: 10,
                          }}
                        >
                          <Barchart data={chartData} />
                        </div>
                      )}
                    </div>

                    <div className="report-summary-sheet">
                      <table className="report-summary-table">
                        <tbody>
                          <tr>
                            <td>
                              <strong>Remarks from teacher</strong>
                            </td>
                            <td colSpan={3}>
                              {!empty(studentReport) &&
                              !empty(studentReport.comment)
                                ? studentReport.comment
                                : !empty(studentReport) &&
                                  !empty(studentReport.teachersComment)
                                ? studentReport.teachersComment
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td>Teacher's Name</td>
                            <td colSpan={3}>{classTeacherName}</td>
                          </tr>
                          <tr>
                            <td>Principal's Remark</td>
                            <td colSpan={3}>
                              {!empty(studentReport) &&
                              !empty(studentReport.principalComments)
                                ? studentReport.principalComments
                                : "NA"}
                            </td>
                          </tr>
                          <tr>
                            <td>Director's Name</td>
                            <td>Mr. Ukoha Orji Okoro</td>
                            <td colSpan={2}>
                              Sign: <em>Management</em>
                            </td>
                          </tr>
                          <tr>
                            <td>Next Term Begins</td>
                            <td>{!empty(openingDate) ? openingDate : "NA"}</td>
                            <td>Date: </td>
                            <td>{!empty(closingDate) ? closingDate : "NA"}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <div className="mt-50 assessment_not_paid">
                    <FaExclamationTriangle color="red" size={25} />
                    <p className="mt-10 fs-18">
                      <strong>Result Sheet Unavailable</strong>
                    </p>
                    <span>This student has an outstanding portal levy</span>
                  </div>
                )}
              </div>
            </div>
            {hasPaid && (
              <div className="flex flex-center mt-20">
                <ButtonIcon
                  height={45}
                  marginTop={2}
                  color={colors.white}
                  backgroundColor={colors.primary}
                  width={120}
                  borderColor={colors.primary}
                  buttonText="Print Result"
                  type="submit"
                  onClick={() => printReport()}
                />
              </div>
            )}
          </div>
        </main>
        {isActionLoading && <FullPageLoader visible={isActionLoading} />}
      </AppWrapper>
      <Toast ref={toastTR} style={{ zIndex: 99999 }} position="bottom-left" />
    </>
  );
};

export default DisplayReportSheet;
