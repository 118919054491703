import { useRef, useState } from "react";
import { empty, isString, toSentenceCase } from "../../Utilities/utils";
import { Toast } from "primereact/toast";

// css
import "../students/Students.css";
import "../dashboard/Dashboard.css";
import "../staff/Staff.css";

// components
import MainHeader from "../../components/headers/mainHeader/MainHeader";
import Card from "../../components/card/Card";
import { useContext } from "react";
import { AuthContext } from "../Root/ProtectedRoute";
import { Avatar } from "primereact/avatar";
import Underline from "../../components/others/Underline";
import ItemLoading from "../../components/skeleton/ItemLoading";
import SettingsWrapper from "../../components/appWrapper/SettingsWrapper";

const Profile = ({ ...props }) => {
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false); // (WIP)
  const toastTR = useRef(null);

  const firstName =
    !empty(user) && !empty(user.firstName) ? user.firstName : "";
  const lastName = !empty(user) && !empty(user.lastName) ? user.lastName : "";
  const fullName =
    !empty(user) && !empty(user.fullName) && isString(user.fullName)
      ? toSentenceCase(user.fullName)
      : "";
  const firstNameInitial = firstName.charAt(0);
  const lastNameInitial = lastName.charAt(0);
  const middleName =
    !empty(user) && !empty(user.middleName) ? user.middleName : "";
  const gender = !empty(user) && !empty(user.gender) ? user.gender : "";
  const title =
    !empty(user) && !empty(user.title) && isString(user.title)
      ? toSentenceCase(user.title)
      : "";
  const address = !empty(user) && !empty(user.address) ? user.address : "";
  const stateOfResidence =
    !empty(user) && !empty(user.stateOfResidence) ? user.stateOfResidence : "";
  const lgaOfResidence =
    !empty(user) && !empty(user.lgaOfResidence) ? user.lgaOfResidence : "";
  const stateOfOrigin =
    !empty(user) && !empty(user.stateOfOrigin) ? user.stateOfOrigin : "";
  const lgaOfOrigin =
    !empty(user) && !empty(user.lgaOfOrigin) ? user.lgaOfOrigin : "";
  const nationality =
    !empty(user) && !empty(user.nationality) ? user.nationality : "";
  const religion = !empty(user) && !empty(user.religion) ? user.religion : "";
  const active = !empty(user) && !empty(user.active) ? user.active : "";
  const phoneNumber1 =
    !empty(user) && !empty(user.phoneNumber1) ? user.phoneNumber1 : "";
  const phoneNumber2 =
    !empty(user) && !empty(user.phoneNumber2) ? user.phoneNumber2 : "";
  const email = !empty(user) && !empty(user.email) ? user.email : "";
  const qualification =
    !empty(user) && !empty(user.qualification) ? user.qualification : "";
  const courseStudied =
    !empty(user) && !empty(user.courseStudied) ? user.courseStudied : "";
  const dateOfBirth =
    !empty(user) && !empty(user.dateOfBirth) ? user.dateOfBirth : "";
  const qrCodePath =
    !empty(user) && !empty(user.qrCodePath) ? user.qrCodePath : "";

  const avatar = !empty(user) && !empty(user.avatar) ? user.avatar : "";

  return (
    <>
      <SettingsWrapper {...props}>
        <main>
          {/* menu header */}
          <MainHeader title="Staff Profile" />
          {/* end menu header */}
          <div
            className="mt-10"
            style={{ display: "flex", flexDirection: "row" }}
          >
            {/* left box */}
            {/* chart */}
            <div className="chart_box">
              <Card
                children={
                  <>
                    <div className="dashboard_school_list">
                      <div className="avatar-container flex">
                        <div className="staff-avatar-container">
                          <p className="fs-20 bold">
                            {title} {fullName}
                          </p>
                          {!empty(avatar) ? (
                            <Avatar
                              image={avatar}
                              shape="circle"
                              style={{
                                width: 200,
                                height: 200,
                                padding: 10,
                              }}
                              className="p-overlay-badge  mb-20"
                            ></Avatar>
                          ) : (
                            <Avatar
                              label={lastNameInitial + firstNameInitial}
                              shape="circle"
                              style={{
                                width: 150,
                                height: 150,
                                fontSize: 70,
                              }}
                              className="p-overlay-badge  mb-20"
                            ></Avatar>
                          )}
                        </div>
                        <div className="personal-info-block">
                          <div className="personal-info-list">
                            <div className="profile-title">First Name: </div>
                            <div className="profile-value">
                              {!isLoading ? firstName : <ItemLoading />}
                            </div>
                          </div>
                          <div className="personal-info-list">
                            <div className="profile-title">Last Name: </div>
                            <div className="profile-value">
                              {!isLoading ? lastName : <ItemLoading />}
                            </div>
                          </div>
                          <div className="personal-info-list">
                            <div className="profile-title">Middle Name: </div>
                            <div className="profile-value">
                              {!isLoading ? middleName : <ItemLoading />}
                            </div>
                          </div>
                          <div className="personal-info-list">
                            <div className="profile-title">Gender: </div>
                            <div className="profile-value">
                              {!isLoading ? gender : <ItemLoading />}
                            </div>
                          </div>
                          <div className="personal-info-list">
                            <div className="profile-title">Title: </div>
                            <div className="profile-value">
                              {!isLoading ? title : <ItemLoading />}
                            </div>
                          </div>
                        </div>
                        <div className="personal-info-block">
                          <div className="personal-info-list">
                            <div className="profile-title">Address: </div>
                            <div className="profile-value">
                              {!isLoading ? address : <ItemLoading />}
                            </div>
                          </div>
                          <div className="personal-info-list">
                            <div className="profile-title">
                              State of Residence:{" "}
                            </div>
                            <div className="profile-value">
                              {!isLoading ? stateOfResidence : <ItemLoading />}
                            </div>
                          </div>
                          <div className="personal-info-list">
                            <div className="profile-title">
                              L.G.A of Residence:{" "}
                            </div>
                            <div className="profile-value">
                              {!isLoading ? lgaOfResidence : <ItemLoading />}
                            </div>
                          </div>
                          <div className="personal-info-list">
                            <div className="profile-title">
                              State of Residence:{" "}
                            </div>
                            <div className="profile-value">
                              {!isLoading ? stateOfOrigin : <ItemLoading />}
                            </div>
                          </div>
                          <div className="personal-info-list">
                            <div className="profile-title">
                              L.G.A of Origin:{" "}
                            </div>
                            <div className="profile-value">
                              {!isLoading ? lgaOfOrigin : <ItemLoading />}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="other-staff-info-header">
                        <MainHeader title="Other Information" />
                      </div>
                      <div className="flex sm-reverse-direction-vertical other-staff-info col-g-12">
                        <div className="personal-info-block">
                          <div className="personal-info-list">
                            <div className="profile-title">Nationality: </div>
                            <div className="profile-value">
                              {!isLoading ? nationality : <ItemLoading />}
                            </div>
                          </div>
                          <div className="personal-info-list">
                            <div className="profile-title">Religion</div>
                            <div className="profile-value">
                              {!isLoading ? religion : <ItemLoading />}
                            </div>
                          </div>
                          <div className="personal-info-list">
                            <div className="profile-title">Date of Birth</div>
                            <div className="profile-value">
                              {!isLoading ? dateOfBirth : <ItemLoading />}
                            </div>
                          </div>
                          <div className="personal-info-list">
                            <div className="profile-title">Phone Number 1</div>
                            <div className="profile-value">
                              {!isLoading ? phoneNumber1 : <ItemLoading />}
                            </div>
                          </div>
                        </div>

                        <div className="personal-info-block">
                          <div className="personal-info-list">
                            <div className="profile-title">Phone Number 2</div>
                            <div className="profile-value">
                              {!isLoading ? phoneNumber2 : <ItemLoading />}
                            </div>
                          </div>
                          <div className="personal-info-list">
                            <div className="profile-title">Email: </div>
                            <div className="profile-value">
                              {!isLoading ? email : <ItemLoading />}
                            </div>
                          </div>
                          <div className="personal-info-list">
                            <div className="profile-title">Qualification</div>
                            <div className="profile-value">
                              {!isLoading ? qualification : <ItemLoading />}
                            </div>
                          </div>
                          <div className="personal-info-list">
                            <div className="profile-title">Course Studied</div>
                            <div className="profile-value">
                              {!isLoading ? courseStudied : <ItemLoading />}
                            </div>
                          </div>
                        </div>
                        <div className="personal-info-block">
                          <img
                            width={250}
                            height={250}
                            src={qrCodePath}
                            alt="qrcode"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                }
                addStyle="student_list_box"
              />
            </div>
            {/* end chart */}
          </div>
        </main>
        <Toast ref={toastTR} position="bottom-left" />
      </SettingsWrapper>
    </>
  );
};

export default Profile;
