import client from "./Client";

const recordAssessment = (
  school_id,
  student_id,
  ca1 = 0,
  ca2 = 0,
  exam = 0,
  subject_id,
  user_id
) =>
  client.post(
    "/staff/assessment/record",
    {
      school_id,
      student_id,
      ca1,
      ca2,
      exam,
      subject_id,
      user_id,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

const addStudentPerformanceComment = (
  school_id,
  student_id,
  comment,
  user_id
) =>
  client.post(
    "/staff/assessment/comment/update",
    {
      school_id,
      student_id,
      comment,
      user_id,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

const getRatings = (school_id) =>
  client.get(`/staff/school/${school_id}/rating-indices`);

const getAffectiveDomains = (school_id) =>
  client.get(`/staff/school/${school_id}/affective-domains`);

const getPsychomotor = (school_id) =>
  client.get(`/staff/school/${school_id}/psychomotor`);

const addAffectiveDomainForStudent = (
  school_id,
  student_id,
  session_id,
  term_id,
  values
) =>
  client.post("/staff/student/affective-domain/add", {
    school_id,
    student_id,
    session_id,
    term_id,
    values,
  });

const addPsychomotorForStudent = (
  school_id,
  student_id,
  session_id,
  term_id,
  values
) =>
  client.post("/staff/student/psychomotor/add", {
    school_id,
    student_id,
    session_id,
    term_id,
    values,
  });

export default {
  recordAssessment,
  addStudentPerformanceComment,
  getAffectiveDomains,
  getPsychomotor,
  getRatings,
  addAffectiveDomainForStudent,
  addPsychomotorForStudent,
};
