import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function LogOut() {
  const navigate = useNavigate();

  useEffect(() => {
    const staffToken = localStorage.getItem("staffAccessToken");
    if (staffToken) {
      localStorage.removeItem("");
    }
    navigate("/");
  }, []);

  return <div>Logged Out</div>;
}

export default LogOut;
