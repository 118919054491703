import client from "./Client";

const getClasses = (schoolId) =>
  client.get(`/staff/school/${schoolId}/classes`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

const getStaffClasses = (schoolId, staffId) =>
  client.get(`/staff/${staffId}/school/${schoolId}/classes`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

const postionClass = (
  school_id,
  class_id,
  session_id,
  term_id,
  subjects_to_assess
) =>
  client.post(
    "/staff/class/assessment/position",
    {
      class_id,
      session_id,
      term_id,
      subjects_to_assess,
      school_id,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

const getSingleClass = (class_id, school_id) =>
  client.get(`/staff/school/${school_id}/class/${class_id}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

const getNumOfStudents = (class_id, school_id) =>
  client.get(`/staff/school/${school_id}/stat/class/${class_id}/students`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

const getNumOfClassSubjects = (class_id, school_id) =>
  client.get(`/staff/school/${school_id}/stat/class/${class_id}/subjects`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

const getClassAssessmentPercentage = (class_id, school_id) =>
  client.get(`/staff/school/${school_id}/stat/class/${class_id}/assessment`, {
    headers: {
      "Content-Type": "application/json",
    },
  });

const getStudentsInClassPerformance = (
  class_id,
  school_id,
  termId,
  sessionId,
  limit = null
) => {
  const params = {};

  if (termId) {
    params.termId = termId;
  }

  if (limit) {
    params.limit = limit;
  }

  if (sessionId) {
    params.sessionId = sessionId;
  }

  return client.get(
    `/staff/school/${school_id}/class/${class_id}/performance/students`,
    {
      params: params,
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const getNumberOfStudentsByGender = (
  class_id,
  school_id,
  termId,
  sessionId
) => {
  const params = {};

  if (termId) {
    params.termId = termId;
  }

  if (sessionId) {
    params.sessionId = sessionId;
  }

  return client.get(
    `/staff/school/${school_id}/stat/class/${class_id}/gender/students`,
    {
      params,
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const getSubjectsInClass = (class_id, school_id, limit) => {
  const params = {};
  if (limit) {
    params.limit = limit;
  }
  return client.get(`/staff/school/${school_id}/class/${class_id}/subjects`, {
    params,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const getStaffSubjectsInClass = (class_id, staff_id, school_id) => {
  return client.get(
    `/staff/school/${school_id}/class/${class_id}/staff/${staff_id}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const getAssessmentPercentage = (class_id, school_id) =>
  client.get(`staff/school/${school_id}/stat/class/${class_id}/assessment`);

export default {
  getAssessmentPercentage,
  getClassAssessmentPercentage,
  getNumOfClassSubjects,
  getNumOfStudents,
  getClasses,
  getSingleClass,
  getStudentsInClassPerformance,
  getNumberOfStudentsByGender,
  getSubjectsInClass,
  postionClass,
  getStaffSubjectsInClass,
  getStaffClasses,
};
