// const APP_BASE_URL = "https://staging-staff.anciemdemia.com";
// const API_STAFF_URL = "https://api.staging.anciemdemia.com/v1";
// const API_BASE_URL = "https://api.staging.anciemdemia.com/v1";

// const APP_BASE_URL = "http://172.20.10.9:3001";
// // const API_STAFF_URL = "http://localhost:8052";
// const API_STAFF_URL = "http://172.20.10.9:8052";
// const API_BASE_URL = "http://172.20.10.9:8052";

const APP_BASE_URL = "https://staff.anciemdemia.com";
const API_STAFF_URL = "https://api.anciemdemia.com/v1";
const API_BASE_URL = "https://api.anciemdemia.com/v1";

export { API_BASE_URL, API_STAFF_URL, APP_BASE_URL };
